import React, { useEffect, useState } from 'react';

const Img = (props) => {
	const [ imgSrc, setImgSrc ] = useState('');

	useEffect(() => {
		if(props)
		{
		    var arrayBufferView = new Uint8Array( props.src );
		    var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );
		    var urlCreator = window.URL || window.webkitURL;
		    var x = urlCreator.createObjectURL( blob );
		    setImgSrc(x)
		}
	},[])

  return (
    <img src={imgSrc} {...props}/>
  )
}


export default Img;