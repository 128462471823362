import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function Contact() {
  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Contact <span>Us</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/careers" activeclass="active">Contact Us</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>


		<section className="inner-page-wrapper about-wrapper">
			<div className="container">
			 	<div className="row">
					<table className="table table-hover">
					  <thead>
					    <tr>
					      <th scope="col">Area</th>
					      <th scope="col">Contact Number</th>
					      <th scope="col">Offices</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr>
					      <th scope="row">Hotline/Consumer Welfare</th>
					      <td>0928-502-7969</td>
					      <td>Main Office</td>
					    </tr>
					    <tr>
					      <th scope="row">North Area Sub Office</th>
					      <td>
					      	<p> 0999-990-5173 </p>
					      	<p> 0947-890-9967 </p>
					      </td>
					      <td>
					      	<p> Bani/Agno </p>
					      	<p> Bolinao/Anda </p>
					      </td>
					    </tr>
					    <tr>
					      <th scope="row">South Area Sub Office</th>
					      <td>
					      	<p> 0939-921-1853 </p>
					      	<p> 0939-902-6027 </p>
					      </td>
					      <td>
					      	<p> Mabini/Burgos/Dasol/Infanta </p>
					      	<p> Alaminos City </p>
					      </td>
					    </tr>
					    <tr>
					      <th scope="row">Technical Services</th>
					      <td>
					      	<p> 0920-947-1031 </p>
					      	<p> 0918-937-6487 </p>
					      </td>
					      <td>
					      	<p> TSD-Manager </p>
					      	<p> Engineering Services </p>
					      </td>
					    </tr>
					  </tbody>
					</table>
				</div>
			</div>
		</section>
      <CTAStrip/>
  	</>
  );
}

export default withRouter(Contact);
