import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutBod() {
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Board Of <span>Directors</span></h1>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/bod" activeclass="active">Board of Directors</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		<section className="inner-page-wrapper about-wrapper">
			<div className="container text-center about-bod">

				<div className="container">
				  <div className="row">
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				        	<p className="mb-0">Name</p>
				          <p className="mb-0">Chairperson</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				        	<p className="mb-0">Name</p>
				          <p className="mb-0">Vice-Chairperson</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				  </div>
				</div>

				<div className="container">
				  <div className="row">
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Secretary</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Treasurer</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Member</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Member</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Member</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Member</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				    <div className="col mb-4">
				        <div>
				        	<img src="/assets/images/about/BOD/image.png" width="200" height="200"></img>
				          <p className="mb-0">Name</p>
				          <p className="mb-0">Member</p>
				          <p className="mb-0">District</p>
				        </div>
				    </div>
				  </div>
				</div>

			</div>
		</section>

			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutBod);
