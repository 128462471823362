import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';

// Live imports
import axios from 'axios';
import parse from 'html-react-parser';
import moment from 'moment';
import Loader from 'react-loader-spinner'

import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function NewsPowerInterruptions() {

  const [ powerInterruptions, setPowerInterrptions ] = useState([]);
  const [isLoading,setIsLoading] = React.useState(true);

  useEffect(() => {
  	getLists();

  }, []);

  const getLists = async () => {
    await axios.get(route.root.powerinterruption + '/incoming')
      .then(async res => {
        // console.log(res.data)
        setPowerInterrptions(res.data)
        setTimeout(() => {
        	setIsLoading(false)
        }, 2000)
      })
      .catch((error) => {
        console.log(error);
      })

		setTimeout(() => {
	  	window.$(document).ready( function () {
			    window.$('.nopi-table').DataTable();
			} );
		}, 2000)
  }

  return (
  	<>
      <PageLoader/>  
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Power Interruptions</h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/news" activeclass="active">News</Link>
                  		</li>
			                <li>
                  			<Link exact to="/news/power-interruptions" activeclass="active">Power Interruptions</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<section className="inner-page-wrapper about-wrapper pb-5 mb-5">
			  <div className="container position-relative">

          {
            (isLoading)?
              <div className="container">
                <div className="text-center"><Loader type="TailSpin" color="#f10d08" height={70} width={70} timeout={9000}/><h6>LOADING...</h6></div>
              </div>
            : null
          }
          
			  	<div className="row">
				  	<div className="col">
					  	<div className="card">
						  	<div className="card-body">
									{/**<p className="text-right mt-5"><small><i>*Last update:</i> <i className="text-danger">3 hours ago </i> <i> <strong> (January 11, 2021)</strong></i></small> </p>**/}

									<table className="table nopi-table">
									  <thead>
									    <tr>
									      <th scope="col">Date</th>
									      <th scope="col">Time</th>
									      <th scope="col">Activities</th>
									      <th scope="col">Affected Areas</th>
									      <th scope="col">Circuit to be Opened</th>
									    </tr>
									  </thead>
									  <tbody>

										  {
										  	powerInterruptions
										  	.map((i, k) => {
											    return (<tr key={k}>
											      <td>
											      	<p className="mb-0">{moment(i.date.unix).format("MMMM D, YYYY", "Asia/Manila")}</p>
											      	<p className="mb-0">({moment(i.date.unix).format('dddd', "Asia/Manila")})</p>
											      </td>
											      <td>{i.time.start} - {i.time.end}</td>
											      <td>
											      	{parse(i.activities)}
											      </td>
											      <td>
											      	{parse(i.affectedAreas)}
											      </td>
											      <td>
											      	{parse(i.circuitToBeOpened)}
											      </td>
											    </tr>)
										  	})
										  }
									  </tbody>
									</table>
									<div className="">
									  <p><strong>Note: We may energize the lines earlier than scheduled so PLEASE CONSIDER OUR LINES AND EQUIPMENT ENERGIZED AT ALL TIMES.</strong></p>
									</div>
					    	</div>
				    	</div>
			    	</div>
			    </div>
			  </div>
			</section>

      <CTAStrip/>
  	</>
  );
}

export default withRouter(NewsPowerInterruptions);
