import React, { useEffect, useState } from 'react';

const AgamRegFormGroup = (props) => {
	const [ reason, setReason ] = useState('');
	const [ ansVal, setAnsVal ] = useState('');
	const [ showNext, setShowNext ] = useState(false);
	const { details, cbDecision, activeKey, aKey} = props;
	const { details : { AT_ID, AT_SEQUENCE_NUMBER, AT_TOPIC_DESC, AT_TOPIC}} = props;

	useEffect(() => {
		// console.log(reason)
	},[])


	const setDecision = async (e, v, p) => {
        window.$('#disagree' + AT_ID ).css({'display':(parseInt(v) < 1)? 'block' : 'none'})
        setReason( (parseInt(v) > 0)? '' : reason); 
        setAnsVal(v);
        setShowNext(true)
	}
  const _setReason = (event) => { 
  	setReason(event.target.value);
  }

  const	_cbReturn = async () => {  		
  		await props.activeKey(aKey + 1)
      return props.cbDecision({
      	topic_sequence_id: AT_SEQUENCE_NUMBER,
      	answer_topic_id: AT_ID,
      	answer_value: ansVal,
      	answer_topic_reason: reason
      });
	}


  return (    
    <div className="card mb-3">
        <div className="card-body">
        <>
            <div className="mb-3">
                <div>
                    <h2>{AT_SEQUENCE_NUMBER}. {AT_TOPIC}</h2>
                        <p>{AT_TOPIC_DESC}</p>
                    <div>
                    </div>
                </div>
            </div>
            <div className="checkbox">
                <label>
                <input type="radio" onClick={(e) => {setDecision(e, 1, AT_ID)}} name={`decision${AT_ID}`} value="1"/> Approve</label>
            </div>
            <div className="checkbox">
                <label>
                <input type="radio" onClick={(e) => {setDecision(e, 0, AT_ID)}} name={`decision${AT_ID}`} value="0"/> Disapprove</label>
            </div>
            <div className="mb-3" id={`disagree${AT_ID}`} style={{"display":"none"}}>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">Why disagree?</label>
                <textarea className="form-control" onChange={_setReason} value={reason} rows="3"></textarea>
            </div>

            <div className="row">
	            <div className="col-sm-6">
		            {/*
		            	(AT_SEQUENCE_NUMBER > 1)? 
					            <button type="button" onClick={() => _cbReturn()} className="btn btn-primary btn-md text-left">Prev</button>
		            	: null*/
		            }
	            </div>
	            <div className="col-sm-6">
			          <div className="text-right">
			            <button type="button" disabled={(showNext)? false : true} title="Please choose between Agree or Disagree. Thank you!" onClick={() => _cbReturn()} className="btn btn-primary btn-md ">Next</button>
			          </div>
	            </div>
            </div>

        </>
        </div>
    </div>
  )
}


export default AgamRegFormGroup;