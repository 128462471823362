import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function News() {
  return (
  	<>
      <PageLoader/>    
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>News</h1>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			                <ul>
				                <li>
	                  			<Link exact to="/" activeclass="active">Home</Link>
	                  			</li>
				                <li>
	                  			<Link exact to="/news" activeclass="active">News</Link>
	                  		    </li>
			                </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<section className="inner-page-wrapper blog-wrapper">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-4 col-md-6">
			        <div className="item blog_card">
			          <div className="post-img"> <img src="/assets/images/news/blog1.jpg" alt=""/>
			            <div className="posted_on"> <span className="date">19</span> <span className="month">July</span> </div>
			          </div>
			          <div className="post-detail">
			            <h5><a href="blog-details.html">What is Lorem Ipsum?</a></h5>
			            <div className="post-status">
			              <ul>
			                <li><i className="fa fa-folder"></i> <span><a href="/news/news-id">UI Developer</a></span></li>
			                <li><i className="fa fa-comments-o"></i> <span>20</span></li>
			              </ul>
			            </div>
			            <div className="description">
			              <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
			              <a href="blog-details.html" className="theme-button-one bttn color-one" data-text="Learn More"><span>Learn More</span></a> </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-6">
			        <div className="item blog_card">
			          <div className="post-img"> <img src="/assets/images/news/blog2.jpg" alt=""/>
			            <div className="posted_on"> <span className="date">19</span> <span className="month">July</span> </div>
			          </div>
			          <div className="post-detail">
			            <h5><a href="blog-details.html">Why do we use it?</a></h5>
			            <div className="post-status">
			              <ul>
			                <li><i className="fa fa-folder"></i> <span><a href="/news/news-id">UI Developer</a></span></li>
			                <li><i className="fa fa-comments-o"></i> <span>20</span></li>
			              </ul>
			            </div>
			            <div className="description">
			              <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
			              <a href="blog-details.html" className="theme-button-one bttn color-one" data-text="Learn More"><span>Learn More</span></a> </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-6">
			        <div className="item blog_card">
			          <div className="post-img"> <img src="/assets/images/news/blog3.jpg" alt=""/>
			            <div className="posted_on"><span className="date">19</span> <span className="month">July</span> </div>
			          </div>
			          <div className="post-detail">
			            <h5><a href="blog-details.html">Where does it come?</a></h5>
			            <div className="post-status">
			              <ul>
			                <li><i className="fa fa-folder"></i> <span><a href="/news/news-id">UI Developer</a></span></li>
			                <li><i className="fa fa-comments-o"></i> <span>20</span></li>
			              </ul>
			            </div>
			            <div className="description">
			              <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
			              <a href="blog-details.html" className="theme-button-one bttn color-one" data-text="Learn More"><span>Learn More</span></a> </div>
			          </div>
			        </div>
			      </div>	
			    </div>
			  </div>
			</section>
			<CTAStrip/>
  	</>
  );
}

export default withRouter(News);
