import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';
import MaskInput from 'react-maskinput';
import ReCAPTCHA from 'react-google-recaptcha';

function ServiceBillInquiry() {

  const [accountNumber, setAccountNumber] = useState('');
  const [verifyCallback, setVerifyCallback] = useState('');
  const [errorCallback, setErrorCallback] = useState('');
  const [expiredCallback, setExpiredCallback] = useState('');
  const onChangeAccountNumber = (e) => {
    var val = e.target.value;
    if(val.match(/^\d{2}-\d{4}-\d{4}$/)){
      setAccountNumber(val);
    }
    else
    {
      setAccountNumber('');
    }
  };

  const rverifyCallback = (response) => {
    setVerifyCallback(response);
  };
  const rerrorCallback = () => {
    setVerifyCallback('');
  };
  const rexpiredCallback = () => {
    setVerifyCallback('');
  };
  useEffect(() => {
    window.rverifyCallback = rverifyCallback;
    window.rerrorCallback = rerrorCallback;
    window.rexpiredCallback = rexpiredCallback;
    window.$('.default-form').submit((e) => {
      e.preventDefault();
    })

  }, []);
  return (
  	<>
      <PageLoader/>
				<div className="breadcrumb-wrapper">
				  <div className="images-overlay"></div>
				  <div className="container">
				    <div className="row">
				      <div className="col-md-12">
				        <div className="page-heading">
				          <h1>Bill <span>Inquiry</span></h1>
				          <span className="line"></span>
				        </div>
				        <div className="page-breadcrumb-inner">
				          <div className="page-breadcrumb">
				            <div className="breadcrumb-list">
				              <ul>
                        <li>
                         <Link exact to="/" className="active">Home</Link>
                        </li>
                        <li>
                         <Link exact to="/services" className="active">Services</Link>
                        </li>
				                <li>
  		              			<Link exact to="/service/bill-inquiry" className="active">Bill Inquiry</Link>
  		              		</li>
				              </ul>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
        <section className="inner-page-wrapper contact-us-wrapper">
          <div className="container">
            <div className="row mb-5">

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="contact-form">
                  <form id="contact-form" name="contact_form" className="default-form" action="?" method="POST">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-box">
                          <input type="text"  placeholder="00-0000-0000" size={10} className="form-control mb-0 account-number" onChange={onChangeAccountNumber}/>
                          <div className="icon"> <i className="fa fa-search" aria-hidden="true"></i> </div>
                        </div>
                          {
                            (accountNumber && verifyCallback)?
                              <p className="mt-2"><small><i>*Last update: 5 days ago (January 5, 2021)</i></small> </p>
                            : 
                              <p className="mt-2"><small><i>*Please enter your Account Number. </i> ex. <strong>01-0123-4567</strong></small></p>
                          }
                      </div>
                      <div className="col-md-6">
                        <div className="input-box">
                        <ReCAPTCHA
                          sitekey="6LdRiioaAAAAAPzgYl0Bz8CenZ9FtMNsFWrOGb3H"
                          onChange={rverifyCallback}
                          onExpired={rexpiredCallback}
                          onErrored={rerrorCallback}
                        />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
            {
              (accountNumber && verifyCallback)?
                <>
                  <div className="row mb-5">
                    <div className="col">
                      <div className="contact-form">
                        <p>Account Number: <strong>{accountNumber}</strong></p>
                        <p>Account Type: <strong>R - Residential</strong></p>
                        <p>Meter Number: <strong>1234567</strong></p>
                        <p>Connection Status: <strong>ACTIVE</strong></p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="contact-form">
                        <p>Account Name: <strong>Juan Dela Cruz</strong></p>
                        <p>Address: <strong>San Jose, Bani, Pangasinan</strong></p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>

                  <div className="contact-form mb-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <p>Current Bill</p>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Year/Month</th>
                              <th scope="col">Reading (<small>Previous - Current</small>)</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Due Date</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p>2020 / January</p>
                              </td>
                              <td>
                                <p>00111 - 00222</p>
                              </td>
                              <td>
                                <p>3,000.00</p>
                              </td>
                              <td>
                                <p><i className="fa fa-calendar" aria-hidden="true"></i> February 10, 2020</p>
                              </td>
                              <td>
                                <p>
                                  <span className="btn btn-md btn-success">View SOA</span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="contact-form mb-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <p>Payment History</p>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Year/Month</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Date Paid</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p>2020 / January</p>
                              </td>
                              <td>
                                <p>3,000.00</p>
                              </td>
                              <td>
                                <p className="mb-0"><i className="fa fa-calendar" aria-hidden="true"></i> February 20, 2020 2:00 PM</p>
                                <p className="mb-0"><small><i className="fa fa-money" aria-hidden="true"></i> Via Cash (Main)</small></p>
                              </td>
                              <td>
                                <p>
                                  <span className="btn btn-md btn-success">DOWNLOAD</span>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>2020 / February</p>
                              </td>
                              <td>
                                <p>3,000.00</p>
                              </td>
                              <td>
                                <p className="mb-0"><i className="fa fa-calendar" aria-hidden="true"></i> March 20, 2020 2:00 PM</p>
                                <p className="mb-0"><small><i className="fa fa-cc-paypal" aria-hidden="true"></i> Via PayPal</small></p>
                              </td>
                              <td>
                                <p>
                                  <span className="btn btn-md btn-success">DOWNLOAD</span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0">Pay via PayMaya</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0">Pay via GCash</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0">Pay via PayPal</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-0">Pay via Credit/Debit Card</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              : null
            }

          </div>
        </section>
        
      <CTAStrip/>
  	</>
  );
}

export default withRouter(ServiceBillInquiry);
