import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutCompanyProfile() {
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>About <span>Us</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/company-profile" activeclass="active">Company Profile</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<section id="about-us" className="inner-page-wrapper about-wrapper">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12 col-xl-9 about-text">
			        <p><span>PANGASINAN I ELECTRIC COOPERATIVE (PANELCO I)</span> is the exclusive franchise holder to operate an electric light and power services in the City of Alaminos and Municipalities of Agno, Anda, Bani, Bolinao, Burgos, Dasol, Infanta, and Mabini - all in the province of Pangasinan.</p>

			        <h3>History of <span> PANELCO I</span></h3>
			        <p>Before the creation of electric cooperatives, small utilities were already operating in different towns in our archipelago but only on a limited basis. In order to address these deficiencies, the government mandated laws to provide the Filipino people an adequate 24-hour electric service at a reasonable cost. On November 7, 1972, Presidential Decree No. 40 was promulgated which converted private municipal electric system to electric cooperatives under R.A. 6038.</p>
			        <p>Later, through Presidential Decree No. 269, which was issued by then President Ferdinand E. Marcos mandated total Rural Electrification of the country under the coverage concept. This concept allowed the construction of lines even at thinly populated areas.</p>
			        <p>With the determined efforts of former presidential Executive Assistant Jocobo C. Clave and former NEA Board of Administrator, Jesus Tanchanco, PANELCO I’s coverage area composing the towns of Agno, Alaminos, Anda, Bani, Bolinao, Burgos, Infanta and Mabini were included to serve a pilot Electric Cooperative.</p>
			       </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12 col-xl-9 about-text">
			        <p>On September 24, 1972, PANELCO I was organized. The Ad-Interim Board was composed of Mr. Arnulfo Cabrera (Bolinao) as President, who later became the General Manager; Atty. Quirubin U. Naraval (Agno) as Vice President; Mr. Pedro C. Elgin Colin (Anda) as Secretary; Mr. Severino C. Rativo (Bani) as Treasurer; Fiscal Romie V. Braga (Burgos) as Director; and Mr. Henry S. Ibarra (Dasol) as Director.</p>
			        <p>Through the initiative of the Ad-Interim Board, the cooperative was incorporated on September 28, 1972 giving birth to the PANGASINAN ELECTRIC COOPERATIVE, INC. ( PANELCO I), under the control and supervision of the National Electrification Administration (NEA).</p>
			        <p>After the incorporation of PANELCO-I, which made the organization a distinct juridical personality, it was able to secure loan of P 11,856,000.00 for its capitalization on December 5, 1972. At that moment, the Coop geared for the real action to implement its avowed goal of servicing all towns under the coverage area of the Cooperative.</p>
			        <p>On March 10, 1993 PANELCO-I was provisionally registered with the Cooperative Development Authority (CDA) and extended the provisional registration until March 4, 2004.</p>
			       </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12 col-xl-9 about-text">
			        <p>The Provisional Authority was enforced as it was not cancelled nor abandoned until its permanent registration on July 19, 2006 wherein a Certificate of Registration was issued.</p>
			        <p>To date, the Cooperative is pursuing its total electrification program through the massive implementation of the Sitio Electrification Program (SEP) which is funded by the Government subsidy.</p>
			      </div>
			      <div className="col-xl-3 about-image d-none d-xl-block"> <img src="/assets/images/about/about-us.png" alt=""/> </div>
			    </div>
			    

			  </div>
			</section>
			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutCompanyProfile);
