import React, { useState , useEffect } from 'react';

// Local imports
import './App.css';
import Header from './components/global/header';
import Footer from './components/global/footer';
import ScrollToTop from './components/global/scrollToTop';
import Home from './components/pages/home';
import About from './components/pages/about';
import PrivacyPolicy from './components/pages/privacy-policy';
import TermsAndConditions from './components/pages/terms-and-conditions';
import AboutCompanyProfile from './components/pages/about-company-profile';
import AboutMissionVision from './components/pages/about-mission-vision-values';
import AboutPledge from './components/pages/about-cooperative-pledge';
import AboutCoverageArea from './components/pages/about-coverage-area';
import AboutOrganizationalStructure from './components/pages/about-organizational-structure';
import AboutBod from './components/pages/about-bod';
import Services from './components/pages/services';
import Gallery from './components/pages/gallery';
import Bids from './components/pages/bids';
import BidsDetails from './components/pages/bids-details';
import News from './components/pages/news';
import NewsAdvisories from './components/pages/news-advisories';
import NewsEventsActivities from './components/pages/news-events-activities';
import NewsPowerInterruptions from './components/pages/news-power-interruptions';
import NewsInvitationToBid from './components/pages/news-invitation-to-bid';
import NewsElectricityRates from './components/pages/news-electricity-rates';
import NewsSupplyAndLoadDemand from './components/pages/news-supply-and-load-demand';
import Career from './components/pages/career';
import ServiceBillsInquiry from './components/pages/service-bill-inquiry';
import ServiceNewConnection from './components/pages/service-new-connection';
import ServiceRequestComplaint from './components/pages/service-request-complaint';
import Contact from './components/pages/contact';
import Faq from './components/pages/faq';
import AgamRegistration from './components/pages/agam-registration';
import AgamRegistrationConfirmation from './components/pages/agam-registration-confirmation';

import AgamStatus from './components/pages/agam-status';
// Live imports
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

function App() {
  // const [date, setDate] = useState(null);
  useEffect(() => {
    
  }, []);

  return (
    <Router>
      <Header/>

      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about-us">
          <About />
        </Route>
        <Route path="/about/company-profile">
          <AboutCompanyProfile />
        </Route>
        <Route path="/about/mission-vision">
          <AboutMissionVision />
        </Route>
        <Route path="/about/pledge">
          <AboutPledge />
        </Route>
        <Route path="/about/coverage-area">
          <AboutCoverageArea />
        </Route>
        <Route path="/about/organizational-structure">
          <AboutOrganizationalStructure />
        </Route>
        <Route path="/about/bod">
          <AboutBod />
        </Route>
        {/*<Route path="/services">
          <Services />
        </Route>*/}
        <Route path="/gallery">
          <Gallery />
        </Route>
        <Route path="/bids">
          <Bids />
        </Route>
        <Route path="/bid/:bid">
          <BidsDetails />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>

        <Route path="/news/advisories">
          <NewsAdvisories />
        </Route>
        <Route path="/news/power-interruption-schedules">
          <NewsPowerInterruptions />
        </Route>
        <Route path="/news/invitation-to-bid">
          <NewsInvitationToBid />
        </Route>
        <Route path="/news/events-and-activities">
          <NewsEventsActivities />
        </Route>
        <Route path="/news/electricity-rates">
          <NewsElectricityRates />
        </Route>
        <Route path="/news/supply-and-load-demand">
          <NewsSupplyAndLoadDemand />
        </Route>
        <Route path="/careers">
          <Career />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
       {/*
        <Route path="/service/bill-inquiry">
          <ServiceBillsInquiry />
        </Route>
        <Route path="/service/new-service-connection">
          <ServiceNewConnection />
        </Route>
        <Route path="/service/request-complaint">
          <ServiceRequestComplaint />
        </Route>*/}
        <Route path="/contact-us">
          <Contact />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/agam-status">
          <AgamStatus />
        </Route>
        {/**<Route path="/agam-registration">
          <AgamRegistration />
        </Route>
        <Route path="/agam-registration-confirmation/:origin/:ac">
          <AgamRegistrationConfirmation />
        </Route>**/}

      </Switch>

      <Footer/>
    </Router>
  );
}

export default App;
