import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutOrganizationalStructure() {
	
  const [ openBox, setOpenBox ] = useState('')
  const [ departments, setDepartments ] = useState([])
	const _openBox = (department) => {
		setOpenBox(department)
	}
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Organizational <span>Structure</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/coverage-area" activeclass="active">Organizational Structure</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<section className="inner-page-wrapper about-wrapper">
				<div className="container text-center org-structure">
				 	<div className="row">
				 		<div className="col mb-4">
				 			<div className="os-box">
				 				<div className="pb-1">Member Consumer Owners</div>
				 			</div>
							<i className="fa fa-arrow-down"></i>
				 		</div>
				 	</div>
				 	<div className="row">
				 		<div className="col mb-4">
				 			<div className="os-box">
				 				<div className="pb-2">Board Of Directors</div>
				 			</div>
							<i className="fa fa-arrow-down"></i>
				 		</div>
				 	</div>
				 	<div className="row">
				 		<div className="col mb-5 text-center">
				 			<div className="os-box">
				 				<div className="pb-2">General Manager</div>
				 			</div>
							<i className="fa fa-arrow-down"></i>
				 		</div>
				 	</div>

				</div>

				<div className="org-managers">
					<div className="container">
						<div className="border-line"></div>
						<div className="row">
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div className="pb-2 top-0" onClick={() => _openBox('cpetitd')}>Corplan, Energy Trading & Information Technology Department (CPETITD)</div>
					 			</div>
					 		</div>
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div onClick={() => _openBox('iad')}>Internal Audit Department (IAD)</div>
					 			</div>
					 		</div>
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div onClick={() => _openBox('fsd')}>Finance Services Department (FSD)</div>
					 			</div>
					 		</div>
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div onClick={() => _openBox('isd')}>Institutional Services Department (ISD)</div>
					 			</div>
					 		</div>
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div onClick={() => _openBox('tsd')}>Technical Services Department (TSD)</div>
					 			</div>
					 		</div>
					 		<div className="col mb-5 text-center">
					 			<i className="fa fa-arrow-down"></i>
					 			<div className="os-box">
					 				<div onClick={() => _openBox('aod')}>Area Operations Department (AOD)</div>
					 			</div>
					 		</div>
					 	</div>
					</div>	
				</div>
				{
					(openBox === 'cpetitd')?

				<div className="container org-emp">
				  <div className="row">
				    <div className="col mb-4 text-center">
				      <div className="os-box">
				      	<div>Department Manager</div>
				      </div>
				      <i className="fa fa-arrow-down"></i>
				    </div>
				  </div>
					<div className="row">
						<div className="col">

							<div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Corplan & Energy Trading Supervisor</div>
						      </div>
					 					<i className="fa fa-arrow-down"></i>
						    </div>
						 </div>

						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Corplan & Regulatory Compliance Officer</div>
						      </div>
					 					<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>

						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Corplan Analyst</div>
						      </div>
					 					<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Energy Trading Officer</div>
						      </div>
					 					<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Utility Economic Analyst</div>
						      </div>
						    </div>
						  </div>
						</div>
						<div className="col">

						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Information Technology Supervisor</div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>IT Specialists/Programmer</div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
							      <div>Computer Network Specialist</div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Information Database Control Officer</div>
						      </div>
						    </div>
						  </div>
						</div>
					</div>				  
				</div>

					: null
				}

				{
					(openBox === 'iad')?

				<div className="container org-emp">
				  <div className="row">
				    <div className="col mb-4 text-center">
				      <div className="os-box">
				        <div>Department Manager</div>
				      </div>
				      <i className="fa fa-arrow-down"></i>
				    </div>
				  </div>

				  <div className="row">
				    <div className="col">
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Financial & System Audit Supervisor</div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>

						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						        <div>
						          <div>Financial Auditors</div>
						        </div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						        <div>
						          <div>System Auditor</div>
						        </div>
						      </div>
						    </div>
						  </div>

				    </div>
				    <div className="col">
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						      	<div>Operations Audit Supervisor</div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						        <div>
						          <div>Technical Auditors</div>
						        </div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4 text-center">
						      <div className="os-box">
						        <div>
						          <div>Line Technology Officer (Apprehension)</div>
						        </div>
						      </div>
					 				<i className="fa fa-arrow-down"></i>
						    </div>
						  </div>
						  <div className="row">
						    <div className="col mb-4">
						      <div className="os-box">
						        <div>
						          <div>Line Technicians (Apprehension)</div>
						        </div>
						      </div>
						    </div>
						  </div>

				    </div>
				  </div>
				</div>

					: null
				}

				{
					(openBox === 'fsd')?

				<div className="container org-emp">
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>Department Manager</div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>

					<div className="row direct">
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Budget Analyst</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Treasury Officer</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Cashiering Assistant</div>
					      </div>
					    </div>
					  </div>
					</div>

				  <div className="row">
				    <div className="col">

							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>General Accounting Supervisor</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Accounting Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Bookkeepers</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Work Order Analyst</div>
							      </div>
							    </div>
							  </div>
							</div>
				    
				    </div>
				    <div className="col">

							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							    	<div className="top-19">Consumer Accounts Control & Billing Supervisor</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Consumer Accounts Control & Billing Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Consumer Data Analyst</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Consumer Accounts Services Officers</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4">
							    <div className="os-box">
							      <div className="top-19">Consumer Accounts Coordinators</div>
							    </div>
							  </div>
							</div>

				    </div>
				  </div>
				  
				</div>

					: null
				}

				{
					(openBox === 'isd')?

				<div className="container org-emp">
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>Department Manager</div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
			  
					<div className="row direct">
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Inventory & Control Officer</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Warehousing Assistant</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Paralegal Officer</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Purchaser</div>
					      </div>
					    </div>
					  </div>
					</div>

				  <div className="row">
				    <div className="col">
						  <div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div className="top-19">Human Resource & Administrative Supervisor</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Human Resource Development Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Industrial Safety & Welfare Analyst</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Payroll Analyst</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Admin & General Services Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Motorpool Analyst</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4">
							    <div className="os-box">
							      <div>
							        <div>Driver-Mechanics</div>
							      </div>
							    </div>
							  </div>
							</div>
				    </div>
				    <div className="col">					
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div className="top-19">Consumer Organization & Development Supervisor</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Consumer Organization & Development Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Member Consumer Records Custodian</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Consumer Information & Relations Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4">
							    <div className="os-box">
							      <div>
							        <div>Consumer Welfare & Relations Analyst</div>
							      </div>
							    </div>
							  </div>
							</div>
				    </div>
				  </div>
				</div>

					: null
				}


				{
					(openBox === 'tsd')?

				<div className="container org-emp">
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>Department Manager</div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>

					<div className="row direct">
					  <div className="col mb-4">
					    <div className="os-box">
					      <div className="top-19">Renewable Energy Development Officer</div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div className="top-19">Renewable Energy Development Analyst</div>
					    </div>
					  </div>
					</div>

				  <div className="row">
				    <div className="col">

							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Distribution Asset Management Supervisor</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Planning & Design Engineer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>System Design Analyst</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Surveyor</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Distribution System Database Officer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Distribution System Database Analysts</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Distribution System Control Analysts</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Special Equipment & Metering Engineer</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Metershop Operator</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Meter Technicians</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Line Technology Officer (Special Equipment)</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>
							        <div>Special Equipment Technician</div>
							      </div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4">
							    <div className="os-box">
							      <div>
							        <div>Line Tecnicians</div>
							      </div>
							    </div>
							  </div>
							</div>
				    
				    </div>
				    <div className="col">

							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Distribution Network Management Supervisor</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Area Operations Engineer</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Line Technology Officers (Operations Maintenance)</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Line Technicians</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Substation Tenders</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Line Technology Officer (Distribution Network)</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4 text-center">
							    <div className="os-box">
							      <div>Line Technicians</div>
							    </div>
							    <i className="fa fa-arrow-down"></i>
							  </div>
							</div>
							<div className="row">
							  <div className="col mb-4">
							    <div className="os-box">
							      <div>Heavy Equipment Operators</div>
							    </div>
							  </div>
							</div>

				    </div>
				  </div>
				</div>

					: null
				}

				{
					(openBox === 'aod')?

				<div className="container org-emp">
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>Department Manager</div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
				  
					<div className="row direct">
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Housewiring Inspectors</div>
					      </div>
					    </div>
					  </div>
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Area Operation Analysts</div>
					      </div>
					    </div>
					  </div>
					</div>

				  <div className="row">
				    <div className="col">
				    
				    </div>
				    <div className="col">

				    </div>
				  </div>

					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>Branch Office Supervisors</div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>
					        <div>Tellers</div>
					      </div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>
					        <div>Consumer Welfare & Relations Coordinators</div>
					      </div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
					<div className="row">
					  <div className="col mb-4 text-center">
					    <div className="os-box">
					      <div>
					        <div>Line Technology Officers (Area Maintenance)</div>
					      </div>
					    </div>
					    <i className="fa fa-arrow-down"></i>
					  </div>
					</div>
					<div className="row">
					  <div className="col mb-4">
					    <div className="os-box">
					      <div>
					        <div>Line Technicians (Area Maintenance)</div>
					      </div>
					    </div>
					  </div>
					</div>
				</div>

					: null
				}

			</section>
			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutOrganizationalStructure);
