import React, { useEffect, useState } from 'react';

// Local imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';
import { months } from '../global/var.global';
import Img from '../widgets/Img';

// Live imports
import axios from 'axios';
import parse from 'html-react-parser';
import moment from 'moment';
import Loader from 'react-loader-spinner'

import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function NewsElectricityRates() {
  const [searchOpen,setSearchOpen] = React.useState(false);
  const [ ml, setMonthLists ] = useState(["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"]);
  const [isLoading,setIsLoading] = React.useState(true);
  const [ residentials, setResidentials ] = useState([]);
  const [ commercials, setCommercials ] = useState([]);
  const [ hvoltages, setHvoltages ] = useState([]);
  const [ currentYear, setCurrentYear ] = useState(0);

  const [ years, setYears ] = useState([]);
  const [ year, setYear ] = useState(0);
  const [ month, setMonth ] = useState(0);
  const [ monthWord, setMonthWord ] = useState('');
  const [ genChargeLists, setGenChargeLists ] = useState([]);

  useEffect(() => {
    getRateLists();
    getGenChargeLists();
  }, [year,month]);


  const getRateLists = async () => {
    const d = new Date();
    const _years = [];
      setYears([]);
    for (var i = 2007; i <= d.getFullYear(); i++) {
      _years.push(i);
    }
    setYears(_years);
    var year  = new Date().getFullYear();
    setCurrentYear(year);
    await axios.get(route.root.newsadvisory + '/power-rates/r/' + year)
      .then(async res => {
        setResidentials(res.data.results)
          setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
    await axios.get(route.root.newsadvisory + '/power-rates/c/' + year)
      .then(async res => {
        setCommercials(res.data.results)
          setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
    await axios.get(route.root.newsadvisory + '/power-rates/hv/' + year)
      .then(async res => {
        setHvoltages(res.data.results)
          setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  }

  const getGenChargeLists =  () => {
    const d = new Date();
    if(year === 0 && month === 0){
      setYear(d.getFullYear());
      setMonth(d.getMonth());
      _getGenCharges(parseInt(d.getMonth()) + 1,d.getFullYear());
    }else{
      _getGenCharges(parseInt(month) + 1,year);
    }
  }

  const _getGenCharges = async (m,y) => {
    await setGenChargeLists([])
    await axios.get(route.root.newsadvisory + '/generation-rates/' + m + '/' + y)
      .then(async res => {
        // console.log(m,y)
        // console.log(res.data.results)
        await setGenChargeLists(res.data.results)
      })
      .catch((error) => {
        console.log(error);
      })
  }


  const onChangeMonth = async (e) => {
      setMonth(0);
    setMonth(parseInt(e.target.value));
      setMonthWord(ml[parseInt(month)]);
      _getGenCharges(parseInt(e.target.value) + 1,year);
  }

  const onChangeYear = async (e) => {
      setYear(0);
    setYear(parseInt(e.target.value));
      _getGenCharges(parseInt(month) + 1,e.target.value);
  }

  return (
    <>
      <PageLoader/>    
      <div className="breadcrumb-wrapper">
        <div className="images-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-heading">
                <h1>Electricity Rates</h1>
                <span className="line"></span>
              </div>
              <div className="page-breadcrumb-inner">
                <div className="page-breadcrumb">
                  <div className="breadcrumb-list">
                    <ul>
                        <li>
                          <Link exact to="/" activeclassname="active">Home</Link>
                        </li>
                        <li>
                          <Link exact to="/news">News</Link>
                        </li>
                        <li>
                          <Link exact to="/news/advisories" activeclassname="active">Electricity Rates</Link>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-wrapper pt-5">
        <div className="container">

      {/**
          <div className="section_heading text-right">
            <p>
              <span className="mr-3"><i className="fa fa-filter"></i> Filter by: <span>Month</span> </span>  
              {(searchOpen)? <input type="text" className="mr-2 searchBox form-control" placeholder="Type here..."/> : null} 
              <span className="hover-pointer" onClick={toggleSearch}>
                {(searchOpen)? <i className="fa fa-close mr-2"></i> : <i className="fa fa-search mr-2"></i>}
                 Search </span> 
            </p>
          </div>**/}

          <div className="row">

            {
              (isLoading)?
                <div className="container">
                  <div className="text-center"><Loader type="TailSpin" color="#f10d08" height={70} width={70} timeout={9000}/><h6>LOADING...</h6></div>
                </div>
              : null
            }

            <div className="col-sm-12">
              <h4>Rates</h4>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{currentYear}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Consumer Type</strong></td>
                    {
                      residentials
                      .sort((a, b) => a.POWER_RATE_ID - b.POWER_RATE_ID)
                      .map((i, k) => {
                        return(<td key={k}>{ml[i.POWER_RATE_MONTH-1]}</td>);
                      })
                    }
                  </tr>
                  <tr>
                    <td>Residential</td>
                    {
                      residentials
                      .sort((a, b) => a.POWER_RATE_ID - b.POWER_RATE_ID)
                      .map((i, k) => {
                        var statPrev = 0;
                        if(k > 0){
                          statPrev =  i.POWER_RATE_VALUE - (residentials[k-1].POWER_RATE_VALUE)
                        }
                        var textStatus = (statPrev > 0)? 'text-danger' : 'text-success';
                        var textIcon =  (statPrev > 0)? 'up' : 'down';
                        return(<td key={k}>{i.POWER_RATE_VALUE} <strong><span className={` ${textStatus}`}> {k < 1? '' : <i className={`fa fa-arrow-${textIcon}`}></i>}{k < 1? '': '('+statPrev.toFixed(4)+')'} </span></strong></td>);
                      })
                    }
                  </tr>
                  <tr>
                    <td>Commercial</td>
                    {
                      commercials
                      .sort((a, b) => a.POWER_RATE_ID - b.POWER_RATE_ID)
                      .map((i, k) => {
                        var statPrev = 0;
                        if(k > 0){
                          statPrev =  i.POWER_RATE_VALUE - (commercials[k-1].POWER_RATE_VALUE)
                        }
                        var textStatus = (statPrev > 0)? 'text-danger' : 'text-success';
                        var textIcon =  (statPrev > 0)? 'up' : 'down';
                        return(<td key={k}>{i.POWER_RATE_VALUE} <strong><span className={` ${textStatus}`}> {k < 1? '' : <i className={`fa fa-arrow-${textIcon}`}></i>}{k < 1? '': '('+statPrev.toFixed(4)+')'} </span></strong></td>);
                      })
                    }
                  </tr>
                  <tr>
                    <td>High Voltage</td>
                    {
                      hvoltages
                      .sort((a, b) => a.POWER_RATE_ID - b.POWER_RATE_ID)
                      .map((i, k) => {
                        var statPrev = 0;
                        if(k > 0){
                          statPrev =  i.POWER_RATE_VALUE - (hvoltages[k-1].POWER_RATE_VALUE)
                        }
                        var textStatus = (statPrev > 0)? 'text-danger' : 'text-success';
                        var textIcon =  (statPrev > 0)? 'up' : 'down';
                        return(<td key={k}>{i.POWER_RATE_VALUE} <strong><span className={` ${textStatus}`}> {k < 1? '' : <i className={`fa fa-arrow-${textIcon}`}></i>}{k < 1? '': '('+statPrev.toFixed(4)+')'} </span></strong></td>);
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-wrapper pt-1 pl-5 pr-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 col-md-10 col-sm-10">
              <h3>Breakdown of Generation Charge</h3>
              <div className="table-fixed-header">
                <table className="table table-bordered table-hover rates-breakdown-table table-responsive ">
                  <thead>
                    <tr>
                      <th ><p className="text-center mb-0">&nbsp;</p><p className="text-center mb-0">Sources</p></th>
                      <th ><p className="text-center mb-0">&nbsp;</p><p className="text-center mb-0">% to Total kWh Purchased</p></th>
                      <th >
                        <p className="text-center mb-0">(A)</p>
                        <p className="text-center">kWh Purchased</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(B)</p>
                        <p className="text-center">Basic Generation Cost (Php)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(C)</p>
                        <p className="text-center">Other Cost Adjustment <small>(DAA, NSS, and other Billing Adjustment)</small> (Php)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(D = B + C)</p>
                        <p className="text-center">Total Generation Cost for the Month (Php)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(E)</p>
                        <p className="text-center">Prompt Payment Discount Availed less PPD Granted (Php)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(F)</p>
                        <p className="text-center">Pilferage Cost Recoveries (Php)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">(G)</p>
                        <p className="text-center">Other Generation Cost Adjustment (OGA) (Php/kWh)</p>
                      </th>
                      <th >
                        <p className="text-center mb-0">&nbsp;</p>
                        <p className="text-center">Generation Rate (Php/kWh)</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="10" className="text-center"><p className="mb-0 text-bold">{year} - {ml[month]}</p></td>
                    </tr>
                      {
                        (genChargeLists.length === 0)?
                      <tr>
                        <td colSpan="10" className="text-center">No data has been found!</td>
                      </tr>
                        : 
                        genChargeLists
                        .map((i, k) => {
                          var total_gen_cost = parseFloat(i['BASIC_GEN_COST'].replace(/,/g, '')) + parseFloat(i['OTHER_COST_ADJUSTMENT'].replace(/,/g, ''));
                          var tgc = parseFloat(total_gen_cost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                          return (
                            <tr key={k}>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['NAME']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['TOTAL_KWH_PURCHASED']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['KWH_PURCHASED']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['BASIC_GEN_COST']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['OTHER_COST_ADJUSTMENT']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{tgc}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['PPDA']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['PCR']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['OGA']}</p></td>
                              <td ><p className={`mb-0 ${(i['NAME'] === 'Total')? 'text-bold' : ''}`}>{i['GEN_RATE']}</p></td>
                            </tr>);
                        })
                      }
                      <tr>
                        <td colSpan="10" className="text-center">&nbsp;</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2">
              <h5 className="text-center my-2">Year and Month</h5>
              <div className="form-group">
                <select className="form-control" value={year} onChange={(e) => onChangeYear(e)}>
                  <option>Select Year</option>
                   {
                    years
                    .sort((a, b) => b - a)
                    .map((i, k) => {
                      return (<option value={i} key={k}>{i}</option>);
                    })
                   }
                </select>
              </div>
              <div className="form-group">
                <select className="form-control" value={month} onChange={(e) => onChangeMonth(e)}>
                  <option>Select Month</option>
                  {
                    ml
                    .map((i, k) => {
                      return (<option value={k} key={k}>{i}</option>);
                    })
                  }
                </select>
              </div>
            </div>
          </div>

        </div>
      </section>


      <CTAStrip/>
    </>
  );
}

export default withRouter(NewsElectricityRates);