const host = process.env.REACT_APP_ROOT;
const local_agam = 'http://127.0.0.1:3232/api';
const live_agam = 'http://139.162.47.231:3232/api';
export const route = {
  root: {
    socketio: process.env.REACT_APP_SOCKETIO || 'http://172.16.16.172:4001',
    branch: host + '/branch',
    barangay: host + '/barangay',
    newsadvisory: host + '/news-advisory',
    powerinterruption: host + '/power-interruption',
    agam: host + '/agam/pool',
  },
};