import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function Services() {
  return (
  	<>
  		<PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Our <span>Services</span></h1>
					  <span className="line"></span>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiu tempor incididunt ut labore et dolore magna aliqua.</p>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  			</li>
			                <li>
                  			<Link exact to="/services" activeclass="active">Services</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<section className="inner-page-wrapper services-wrapper">
			  <div className="container">
			    <div className="row">
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="fa fa-home"></i></div>
			          <h3>Residential Services</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="bi bi-spark"></i></div>
			          <h3>Commercial Services</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="bi bi-table-lamp"></i></div>
			          <h3>Industrial Service</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="bi bi-worker-cap"></i></div>
			          <h3>Electrical Repair</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="bi bi-video-cam"></i></div>
			          <h3>Electrical Security</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
			        <div className="single-service-content">
			          <div className="single-services-icon"><i className="bi bi-bulb"></i></div>
			          <h3>Electrical Support</h3>
			          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque, cupiditate debitis dolorem et fugit hic iusto maxime numquam officia. </p>
			          <a href="contact-us.html" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a> </div>
			      </div>
			    </div>
			  </div>
			</section>
			<CTAStrip/>
  	</>
  );
}

export default withRouter(Services);
