import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';

import AgamRegFormGroup from '../widgets/AgamRegFormGroup';
// import socketIOClient from 'socket.io-client';
// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';
import axios from 'axios';

// var socket = socketIOClient(route.root.socketio);

const AgamRegistration = (props) => {
  const [ contactEmail, setContactEmail ] = useState('');
  const [ contactNumber, setContactNumber ] = useState('');
  const [ stabNumber, setStabNumber ] = useState('');
  const [ currentIP, setCurrentIP] = useState('');
  const [ isReg, setIsReg ] = useState(false);
  const [ regCallback, setRegCallback ] = useState();
  const [ dateStarted, setDateStarted ] = useState(0);
  const [ origin, setOrigin ] = useState('00');
  const [ ac, setAC ] = useState('');
  const [ consumer, setConsumer ] = useState({});
  const [ ifExist, setIfExist ] = useState('default');
  const [ at, setActiveTopic ] = useState(0);

  const [ topics, setTopics ] = useState([]);
  const [ answers, setAnswers ] = useState([]);


  const _getCurrentIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    setCurrentIP(res.data.IPv4)
  }

  const _cbDecision = async (data) => {
    // if (answers.indexOf(data.answer_topic_id) === -1) answers.push(data);
    // console.log(answers);
    const _ans = await answers.filter(i => i.answer_topic_id !== data.answer_topic_id);
    if(_ans){
      setAnswers([..._ans, data])
    }
// console.log('my players: ', answers);
    // console.log('_filtered', _filtered);
    // setAnswers([
    //   ...answers, data]);
  }

  const _confirmDecision = async () => {
    // console.log(answers)
    // answers['account_number'] = ac;
    let details = {
      ac: ac,
      contact_number: contactNumber,
      answers: answers,
      origin: origin,
      current_ip: currentIP,
      date_started: dateStarted
    }
    // await axios.post(route.root.agam + '/register-consumer', details)
    //   .then(async res => {
    //     const { data } = res;
    //     console.log(data)
    //     setRegCallback(data)
    //     setAnswers([])
    // })
    // .catch((error) => {
    //   console.log(error);
    // })
    var _origin = (origin)? origin : '';
    var _ac = (ac)? '/'+ac : '';
    window.document.location = '/agam-registration-confirmation/'+ _origin + _ac
  }

  const _setAC = (event) => { 
    setAC(event.target.value);
  }

  const _setContactNumber = (event) => { 
    setContactNumber(event.target.value);
  }


  const _setContactEmail = (event) => { 
    setContactEmail(event.target.value);
  }


  const _activeKey = async (data) => {
    // console.log(data)
    setActiveTopic(data);
  }

  const getConsumer = async () => {
    await axios.get(route.root.agam + '/check-account-number/' + ac)
      .then(async res => {
        const { data } = res;
        // console.log(data)

        await setRegCallback()
        await setAnswers([])
        if(data.result.length > 0){
          await setConsumer(data.result[0]);
          await setIsReg((data.registered > 0)? true : false);
          await setIfExist('exist');
        }
        else
        {
          await setIsReg(false);
          await setConsumer({});
          await setIfExist('not-exist');
        }
        console.log(ifExist)
        setActiveTopic(0)
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const _getTopics = async () => {
    await axios.get(route.root.agam + '/get-all-topics')
      .then(async res => {
        const { data } = res;
        setTopics(data)
        // console.log(data)
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    var _o = ((props.location.search)? props.location.search : '')
    if(_o.split('?origin=')[1])
    {
      setOrigin(_o.split('?origin=')[1])
    }
    setDateStarted(new Date().getTime())
    _getTopics();
    _getCurrentIP();
  },[])


  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>AGAM Registration</h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" >Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/careers" >AGAM Registration</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
								
			<div className="container">
        <div className="row">

            {
              (regCallback)?
                <div className="col-sm-12">

                  <div className="card mb-1 mt-3">
                    <div className="card-body">
                      <h6 className={`mb-0 ${(regCallback.status === 'success')? 'text-success':'text-danger'}`}>{(regCallback.status === 'success')? 'Success!':'Oops!'} {regCallback.message}</h6>
                    </div>
                  </div>
                </div>
              : null
            }
            {/*
              (topics.length === at)?
                <div className="col-sm-12">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="text-center">
                      <h3 className="mb-0">Thank you for your cooperation!</h3>
                    </div>
                  </div>
                </div>
                </div>
              : null*/
            }

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="contact-form mt-5">
                <div name="contact_form" className="default-form">

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-box">
                        <input type="text" onChange={_setAC} placeholder="Your Account Number ex. 0101010090" required/>
                        <div className="icon"> <i className="fa fa-info" aria-hidden="true"></i> </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">

                          <div className="submit-box pl-3">
                            <button className="theme-button-one bttn color-one" data-text="Check" data-loading-text="Please wait..." onClick={() => getConsumer()}>Proceed</button>
                          </div>

                        <div className="col-md-8">
                          <div className="input-box">
                            {(isReg)? 
                              <>
                                <h6 className={`mb-0 mt-3 text-success`}><strong>Oops! Your account has been registered! Click <a href={`/agam-registration-confirmation/${origin}/${ac}`}>here</a></strong></h6>
                              </> : null }

                            {
                              (ifExist === 'not-exist')?
                                <h6 className={`mb-0 mt-3 text-danger`}><strong>Account doesn't exist!</strong></h6>
                              : null
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {
                    (ifExist === 'exist')?
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-box">
                            <input type="text" placeholder="Your Name" readOnly={true} title="Account Name" value={`Account Name: ${consumer.CNAME}`}/>
                            <div className="icon"> <i className="fa fa-user" aria-hidden="true"></i> </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-box">
                            <input type="text" placeholder="Your Name" readOnly={true} title="Membership Number" value={`Membership Number: ${consumer.MEMNO}`}/>
                            <div className="icon"> <i className="fa fa-user" aria-hidden="true"></i> </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-box">
                            <textarea placeholder="Address.." readOnly={true} value={consumer.CADDRESS}></textarea>
                            <div className="icon"> <i className="fa fa-globe" aria-hidden="true"></i> </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-box">
                          {(isReg)? 
                            <input type="text" placeholder="Phone" value="09501234567" readOnly={true}/>
                          : 
                            <input type="text" placeholder="Phone" onChange={_setContactNumber}/>
                          }
                            <div className="icon"> <i className="fa fa-phone" aria-hidden="true"></i> </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-box">
                          {(isReg)? 
                            <input type="text" placeholder="Email" value="email@mail.com" readOnly={true}/>
                          : 
                            <input type="text" placeholder="Email" onChange={_setContactEmail}/>
                          }
                            <div className="icon"> <i className="fa fa-envelope" aria-hidden="true"></i> </div>
                          </div>
                        </div>
                      </div>
                    {/*
                      <div className="row">
                        <div className="col-md-12">
                          <div className="button-box">
                            <button className="theme-button-one bttn color-one" data-text="Submit" data-loading-text="Please wait...">Proceed!</button>
                          </div>
                        </div>
                      </div>
                    */}
                  </>
                  : null
                }

                </div>
              </div>
            </div>

            {
              (answers.length > 0)?
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="contact-form mt-3">
                    <div name="contact_form" className="default-form">
                          <>
                            <h3 className=" text-primary">Results</h3>
                            <div className="">
                            {
                              answers
                              .map((v, k) => {
                                return(
                                  <span className="mr-2" key={k}>
                                    <span className="mb-0"><span title={(v.answer_value > 0)? 'Agree' : 'Disagree'}>{v.topic_sequence_id}. <i className={`fa fa-thumbs-${(v.answer_value > 0)? `up text-success` : `down text-danger`}`} aria-hidden="true"></i> </span> </span>
                                    {/*(v.answer_topic_reason)? <p className="ml-5"><span>Reason</span>: {v.answer_topic_reason}</p> : null */}
                                  </span>
                                );
                              })
                            }
                          </div>
                        </>


                        {
                          (answers.length === topics.length)?

                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-center">
                                <div className="button-box">
                                  <button className="theme-button-one bttn color-one" data-text="Confirm" onClick={() => _confirmDecision()} data-loading-text="Please wait...">Proceed!</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          : null
                        }

                    </div>
                  </div>
                </div>
              : null
            }

            <div className="col-sm-8">
              <div className="mt-3">
                {/*
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="form-inline">
                      <div className="form-group mx-sm-3 mb-2">
                        <label className="mr-2">Account Number </label>
                        <input type="text" onChange={_setAC} className="form-control" placeholder="Account Number"/>
                      </div>
                      <button type="submit" onClick={() => getConsumer()} className="btn btn-primary mb-2">Check!</button>
                      {consumer.registered}
                    </div>

                  </div>
                </div>*/}

                  {
                    (ifExist === 'exist' && isReg === false)?
                      <form>
                          {/*
                          <div className="card mb-3">
                              <div className="card-body">
                                  <h2>Member-Consumer-Owners </h2>
                                  <p>Registration Origin: ALAMINOS CITY <span>Date: </span></p>
                              </div>
                          </div>
                        */}
                        {
                          (topics.length > 0)?
                            topics
                            .map((v, k) => {
                              if(k===at){
                                return(<AgamRegFormGroup activeKey={_activeKey} aKey={k} key={k} details={v} cbDecision={_cbDecision}/>);      
                              } 
                              return null;             
                            })
                          : null
                        }

                      </form>
                  : null
                }

              </div>
            </div>
            <div className="col-sm-4">

            {
              answers.length > 0?

              <div className="mt-3">
                <div className="card mb-3">
                  <div className="card-body">
                    <h3 className=" text-primary">Result</h3>
                    {
                      answers
                      .map((v, k) => {
                        return(
                          <div key={k}>
                            <p className="mb-0">{v.topic_sequence_id}. {(v.answer_value > 0)? 'Agree' : 'Disagree'}</p>
                            {(v.answer_topic_reason)? <p>Reason: {v.answer_topic_reason}</p> : null }
                          </div>
                        );
                      })
                    }
                    {
                      (answers.length === topics.length)?

                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-center">
                            <div className="button-box">
                              <button className="theme-button-one bttn color-one" data-text="Confirm" onClick={() => _confirmDecision()} data-loading-text="Please wait...">Proceed!</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null
                    }
                  </div>
                </div>

              </div>
              : null
            }
            </div>
            </div>
      </div>

      <CTAStrip/>
  	</>
  );
}

export default withRouter(AgamRegistration);
