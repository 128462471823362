import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';

import AgamRegFormGroup from '../widgets/AgamRegFormGroup';
// import socketIOClient from 'socket.io-client';
// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';
import axios from 'axios';

// var socket = socketIOClient(route.root.socketio);

const AgamRegistrationConfirmation = (props) => {
  const [ referenceNumber, setReferenceNumber ] = useState('');
  const [ details, setDetails ] = useState({});

  useEffect(() => {
    const { match: { params: { origin, ac } }} = props
    
    if(origin && ac)
    {      
      axios.get(route.root.agam + '/get-registration-confirmation/'+origin+'/'+ac)
        .then(async res => {
          const { data } = res;
          if(data.length > 0)
          {
            await setDetails(data[0])
          }
          
      })
      .catch((error) => {
        console.log(error);
      })
    }
  },[])

  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>AGAM Registration Confirmation</h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" >Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/agam-registration" >AGAM Registration Confirmation</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
								
			<div className="container">
        <div className="row">
          
          <div className="col-sm-8  pt-5">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">Reference #</th>
                  <td>05-000001</td>
                </tr>
                <tr>
                  <th scope="row">Account #</th>
                  <td>0101010080</td>
                </tr>
                <tr>
                  <th scope="row">Membership #</th>
                  <td>00890</td>
                </tr>
                <tr>
                  <th scope="row">Date Registered</th>
                  <td>Aug 7, 2021</td>
                </tr>
                <tr>
                  <th scope="row">Download PDF</th>
                  <td><a href="#">Click to download the file</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*
          <div className="col-sm-4 pt-5">
            <h3>Statistics</h3>
            <p className="mb-0">Alaminos City 10%</p>
            <p className="mb-0">Bolinao 10%</p>
          </div>
        */}

        </div>
      </div>

      <CTAStrip/>
  	</>
  );
}

export default withRouter(AgamRegistrationConfirmation);
