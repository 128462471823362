import React from 'react';
import { useEffect } from 'react';

// Local imports

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function Footer() {
  useEffect(() => {
    const _year = new Date();
    window.$('#year').text(_year.getFullYear());

    window.$('.partners').owlCarousel({
    loop: true,
    margin: 10,
    autoplay: true,
    smartSpeed: 900,
    autoplayTimeout: 3000,
    responsive:{
        0:{
          items:1
        },
        600:{
          items:1
        },
        700:{
          items:3
        },
        1000:{
          items:4
        },
        1300:{
          items:4
        },
        1600:{
          items:4
        }
      }
    });
  }, []);
  return (
    <>
      <div className="clinets-wrapper">
        <div className="container">
          <div className="owl-carousel partners owl-theme">
            <div className="item"><img alt="" src="/assets/images/global/ngcp-01.png"/></div>
            <div className="item"><img alt="" src="/assets/images/global/mco-01.png"/></div>
            <div className="item"><img alt="" src="/assets/images/global/philreca-01.png"/></div>
            <div className="item"><img alt="" src="/assets/images/global/nea-01.png"/></div>
            <div className="item"><img alt="" src="/assets/images/global/apec-01.png"/></div>
            <div className="item"><img alt="" src="/assets/images/global/pi.png"/></div>
          </div>
        </div>
      </div>
      <footer className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-xl-6">
              <aside className="widget no-padding">
                <div className="about-widget">
                  <div>
                    <iframe src="https://www.google.com/maps?q=San%20Jose%2C%20Bani%2C%20Pangasinan%202407&output=embed&hl=en&z=14"></iframe>
                  </div>
                  <p>San Jose, Bani, <br/>Pangasinan 2407</p>
                  <p>0928-502-7969</p>
                  <p><a className="text-white" href="mailto:panelco_one@yahoo.com">panelco_one@yahoo.com</a></p>
                  <div className="social-icon">
                    <a className="fb" href="https://www.facebook.com/PANELCOI" target="_blank"><i className="fa fa-facebook"></i></a> 
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-3">
              <aside className="widget nav-widget">
                <h3 className="widget-title">Quick Links</h3>
                <ul>
                  <li>
                    <Link exact to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link exact to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link exact to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link exact to="/terms-and-conditions">Terms and Conditions</Link>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-3">
              <aside className="widget nav-widget">
                <h3 className="widget-title">Common FAQ Links</h3>
                <ul>
                  <li>
                    <Link exact to="/faq/1">Benefits of PANELCO I Member Consumer Owner</Link>
                  </li>
                  <li>
                    <Link exact to="/faq/2">Safety and Energy Saving tips</Link>
                  </li>
                  <li>
                    <Link exact to="/faq/3">Report Illegal Connection</Link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p>© Copyright <span id="year"></span> <span className="color">Pangasinan I Electric Cooperative</span> PANELCO I</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Footer);
