import React from 'react';

// Local imports

function CTAStrip() {
  return (
    <>      
      <section className="cta-wrapper">
        <div className="container">
          <div className="col col-lg-12">
            <div className="cta-text">
              {/*<h3 style={{    textTransform: 'none'}}>Do you need any requirement for electrical repair or maintenance?</h3>
              <span className="line"></span>*/}
              <ul>
                <li>Contact Us</li>
                <li><i className="fa fa-headphones"></i>09285027969</li>
                <li><i className="fa fa-envelope"></i>panelco_one@yahoo.com</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CTAStrip;
