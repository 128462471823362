import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutCooperativePledge() {
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>About <span>Us</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/pledge" activeclass="active">Cooperative Pledge</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<section className="inner-page-wrapper about-wrapper">
			  <div className="container">
			    <div className="row">
			      <div className="col about-text text-center">
			      	<div className="coop-pledge mb-5 pb-5" >
			        	<p>As a Filipino</p>
								<p>I am and I believe in the Cooperative</p>
								<p>Alone I am weak</p>
								<p>But with others I am strong</p>
								<p>So I commit myself to work</p>
								<p>To cooperate, for all to be prosperous.</p>
								<p>Harmony, industry I will value.</p>
								<p>Cooperative affairs I will attend,</p>
								<p>Responsibilities I will assume</p>
								<p>The cooperative philosophy I will live.</p>
								<p>One vision,</p>
								<p>One belief,</p>
								<p>One feeling</p>
								<p>In cooperativism, my life I pledge</p>
								<p>So help me God.</p>
			      	</div>
			        
			       </div>
			    </div>
			  </div>
			</section>

			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutCooperativePledge);
