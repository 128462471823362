import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutCoverageArea() {
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>About <span>Us</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/coverage-area" activeclass="active">Coverage Area</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<section className="inner-page-wrapper about-wrapper pb-5 mb-5">
			  <div className="container position-relative">
			  	<div className="row">
			  		<img src="/assets/images/about/coverage-area/coverage.png" className="img-default"/>
			  		<div className="col">
			  			<div className="coverage-box bolinao">
				  			<span className="d-hover" data-toggle="tooltip" title="Bolinao">
				  				<img src="/assets/images/about/coverage-area/bolinao-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/bolinao.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box anda">
				  			<span className="d-hover" data-toggle="tooltip" title="Anda">
				  				<img src="/assets/images/about/coverage-area/anda-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/anda.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box bani">
				  			<span className="d-hover" data-toggle="tooltip" title="Bani">
				  				<img src="/assets/images/about/coverage-area/bani-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/bani.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box agno">
				  			<span className="d-hover" data-toggle="tooltip" title="Agno">
				  				<img src="/assets/images/about/coverage-area/agno-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/agno.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box alaminos">
				  			<span className="d-hover" data-toggle="tooltip" title="Alaminos">
				  				<img src="/assets/images/about/coverage-area/alaminos-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/alaminos.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box burgos">
				  			<span className="d-hover" data-toggle="tooltip" title="Burgos">
				  				<img src="/assets/images/about/coverage-area/burgos-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/burgos.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box mabini">
				  			<span className="d-hover" data-toggle="tooltip" title="Mabini">
				  				<img src="/assets/images/about/coverage-area/mabini-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/mabini.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box dasol">
				  			<span className="d-hover" data-toggle="tooltip" title="Dasol">
				  				<img src="/assets/images/about/coverage-area/dasol-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/dasol.png" className="img-hover"/>
				  			</span>
			  			</div>

			  			<div className="coverage-box infanta">
				  			<span className="d-hover" data-toggle="tooltip" title="Infanta">
				  				<img src="/assets/images/about/coverage-area/infanta-default.png" className="img-default"/>
				  				<img src="/assets/images/about/coverage-area/infanta.png" className="img-hover"/>
				  			</span>
			  			</div>
			  		</div>
			  	</div>
			  </div>
			</section>

			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutCoverageArea);
