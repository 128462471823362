import React, { useEffect } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
      
// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function Home() {

  useEffect(() => {
    if (window.$('.main-slider-carousel').length) {
      window.$('.main-slider-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        animateOut: 'slideOutDown',
          animateIn: 'fadeIn',
          active: true,
        smartSpeed: 2000,
        autoplay: 5000,
        navText: [ '<span className="fa fa-angle-left"></span>', '<span className="fa fa-angle-right"></span>' ],
        responsive:{
          0:{
            items:1
          },
          600:{
            items:1
          },
          1200:{
            items:1
          },
          1300:{
            items:1
          },
          1400:{
            items:1
          },
          1500:{
            items:1
          }
        }
      });
    }  

    window.$('.partners').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        smartSpeed: 900,
        autoplayTimeout: 3000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
      700:{
                items:3
            },
            1000:{
                items:4
            },
      1300:{
                items:5
            },
            1600:{
                items:5
            }
        }
    });

    window.$('.Count').each(function () {
      window.$(this).prop('Counter', 0).animate({
        Counter: window.$(this).text()
      }, {
        duration: 10000,
        easing: 'swing',
        step: function (now) {
            window.$(this).text(Math.ceil(now));
        }
      });
    });

    window.$('.photo-overlay .zoom-icon a').magnificPopup({
      type: 'image',
      gallery:{
        enabled:true
      }
    });  
  }, []);
  return (
    <>
      <PageLoader/>
      <section className="main-slider">
        <div className="main-slider-carousel owl-carousel owl-theme">
          <div className="slide" style={{backgroundImage: 'url(/assets/images/home/banner-1.jpg)'}}>
            <div className="container">
              <div className="content">
                <h1><span>Pangasinan I Electric Cooperative</span>Panelco I</h1>
              </div>
            </div>
          </div>
          <div className="slide" style={{backgroundImage: 'url(/assets/images/home/banner-2.jpg)'}}>
            <div className="container">
              <div className="content">
                <h1><span>Advisories</span></h1>
                <div className="tp-btn"> <a href="/news/advisories" data-text="See More" className="theme-button-one bttn color-one"><span>See More</span></a> </div> 
              </div>
            </div>
          </div>
          <div className="slide" style={{backgroundImage: 'url(/assets/images/home/banner-3.jpg)'}}>
            <div className="container">
              <div className="content">
                <h1><span>POWER INTERRUPTIONS</span></h1>
                <div className="tp-btn"> <a href="/news/power-interruption-schedules" data-text="See More" className="theme-button-one bttn color-one"><span>See More</span></a> </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="features-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-4 no-padding display-flex">
              <div className="single-feature-service" >
                <div className="services-icon"><i className="bi bi-bulb"></i></div>
                <div className="service-content" >
                  <h3>Apply for New Connection</h3>
                  <p className="pb-5">Pre-registration for new service connection</p>
                  <a href="/service/new-service-connection" activeclass="active" data-text="Apply Now" className="theme-button-one bttn color-one" ><span>Proceed</span></a>
                </div>
              </div>
            </div>
            <div className="col-sm-4 no-padding display-flex">
              <div className="single-feature-service two">
                <div className="services-icon"><i className="bi bi-worker-cap"></i></div>
                <div className="service-content" >
                  <h3>View or Report Outages</h3>
                  <p className="pb-5">Report Power Outages</p>
                  <a href="/news/power-interruption-schedules" activeclass="active" data-text="View/Report Now" className="theme-button-two bttn color-two" ><span>Coming Soon</span></a>
                </div>
              </div>
            </div>
            <div className="col-sm-4 no-padding display-flex">
              <div className="single-feature-service three">
                <div className="services-icon"><i className="bi bi-spark"></i></div>
                <div className="service-content" >
                  <h3>Register to PANELCO I Online</h3>
                  <p className="pb-5">Check your account online</p>
                  <a href="/mco/login" activeclass="active" data-text="Login/Register" className="theme-button-one bttn color-one" ><span>Coming Soon</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="about-us" className="about-wrapper">
        <div className="container">
          <div className="section_heading">
            <h2>About <span>Us</span></h2>
            <div className="separator">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="home-about-inner">
                <h2>Since 1975 in serving MCOs.</h2>
                <p>Pangasinan I Electric Cooperative (Panelco I) is the exclusive franchise holder to operate an electric light and power services in the City of Alaminos and Municipalities of Agno, Anda, Bani, Bolinao, Burgos, Dasol, Infanta, and Mabini - all in the province of Pangasinan.</p>
                <a href="/about/company-profile" data-text="Learn More" className="theme-button-one bttn color-one"><span>Learn More</span></a>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="single-about-service">
                    <div className="about-services-icon"> <i className="bi bi-bulb"></i></div>
                    <div className="about-service-content" >
                      <h3>Electrical Services</h3>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="single-about-service">
                    <div className="about-services-icon"> <i className="bi bi-alarm-clock"></i></div>
                    <div className="about-service-content" >
                      <h3>24/7 Services</h3>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="single-about-service">
                    <div className="about-services-icon"> <i className="bi bi-bulb"></i></div>
                    <div className="about-service-content" >
                      <h3>Electrical Lighting</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="counter-wrapper">
        <div id="counter">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="work-statistics text-center"> <i className="icon icon-profile-male stat-icon" aria-hidden="true"></i>
                  <h3 className="Count">95325</h3>
                  <span className="fun-line"></span>
                  <p>Consumers</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="work-statistics text-center"> <i className="icon icon-lightbulb stat-icon" aria-hidden="true"></i>
                  <h3 className="Count">8530</h3>
                  <span className="fun-line"></span>
                  <p>Projects Completed</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="work-statistics text-center"> <i className="icon icon-trophy stat-icon" aria-hidden="true"></i>
                  <h3 className="Count">49</h3>
                  <span className="fun-line"></span>
                  <p>Awards and Citations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="gallery-wrapper home">
        <div className="gallery-content">
          <div className="container">
            <div className="section_heading">
              <h2>Our <span>Cooperative</span></h2>
              <div className="separator">
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img1.jpg" alt="" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img1.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>Consumers</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img2.jpg" alt="" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img2.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>Employees</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img5.jpg" alt="" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img5.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>Substations</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img6.jpg" alt="" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img6.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>Area Offices</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img7.jpg" alt="Image" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img7.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>24/7 Services</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 no-padding">
                <div className="sb-photo"> <img src="/assets/images/home/gallery-img8.jpg" alt="Image" className="img-fluid"/>
                  <div className="photo-overlay">
                    <div className="overlay-content">
                      <div className="zoom-icon"> <a href="/assets/images/home/gallery-img8.jpg"><i className="fa fa-arrows-alt" aria-hidden="true"></i></a> </div>
                      <h2>Board of Directors</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-wrapper">
        <div className="container">
          <div className="section_heading">
            <h2>Our <span>News</span></h2>
            <div className="separator">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/images/home/seal1.jpg" alt=""/>
                  <div className="posted_on"> <span className="date">5</span> <span className="month">October</span>
                </div>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/images/home/data-privacy.pdf" target="_blank">Data Privacy Statement of Pangasinan I Electric Cooperative</a></h5>
                  <div className="description">
                    <p>We commit to maintain the privacy of your personal information.</p>
                    <a href="/assets/images/home/data-privacy.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Learn More"><span>Read More</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/images/home/blog2.jpg" alt=""/>
                  <div className="posted_on"> <span className="date">19</span> <span className="month">July</span>
                </div>
                </div>
                <div className="post-detail">
                  <h5><a href="blog-details.html">Why do we use it?</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">UI Developer</a></span></li>
                      <li><i className="fa fa-comments-o"></i> <span>20</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <a href="blog-details.html" className="theme-button-one bttn color-one" data-text="Learn More"><span>Read More</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/images/home/blog3.jpg" alt=""/>
                  <div className="posted_on"><span className="date">19</span> <span className="month">July</span>
                </div>
                </div>
                <div className="post-detail">
                  <h5><a href="blog-details.html">Where does it come?</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">UI Developer</a></span></li>
                      <li><i className="fa fa-comments-o"></i> <span>20</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <a href="blog-details.html" className="theme-button-one bttn color-one" data-text="Learn More"><span>Read More</span></a> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default withRouter(Home);
