import React, { useEffect, useState } from 'react';

// Local imports
import axios from 'axios';
import { route } from './route.global';

// Live imports
import moment from 'moment';

import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function Header() {

  const [ powerInterruptions, setPowerInterrptions ] = useState([]);
  const [ newsAndAdv, setNewsAndAdv ] = useState([]);

  useEffect(() => {
    var fixed_top = window.$(".header-section");
    window.$(window).on("scroll", function(){
      if( window.$(window).scrollTop() > 50){  
        fixed_top.addClass("animated fadeInDown menu-fixed");
      }
      else{
        fixed_top.removeClass("animated fadeInDown menu-fixed");
      }
    });
    
    window.$('nav.in-navigation').meanmenu({
      meanMenuOpen: '<i className="fa fa-bars"></i>',
      meanMenuClose: '<i className="fa fa-close"></i>',
      meanMenuCloseSize: '18px',
      meanScreenWidth: '991',
      meanExpandableChildren: true,
      meanMenuContainer: '.mobile-menu',
      onePage: true
    });
    window.$('.header-searchtrigger').on('click', function () {
      window.$(this).find('.fa').toggleClass('fa-search fa-close');
      window.$(this).siblings('.header-searchbox').toggleClass('is-visible');
    });

    getNewsAndAdv();
    getIncomingPowerInt();
  }, []);

  const getNewsAndAdv = async () => {
    // await axios.get(route.root.newsadvisory)
    //   .then(async res => {
    //     // console.log(res.data)
    //     setNewsAndAdv(res.data)
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }

  const getIncomingPowerInt = async () => {
    // await axios.get(route.root.powerinterruption + '/incoming')
    //   .then(async res => {
    //     // console.log(res.data)
    //     setPowerInterrptions(res.data)
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }

  return (
    <>
      {
        (powerInterruptions.length > 0)?
          <div className="power-interruptions">
            <p className="mb-0">
              <marquee behavior="scroll" direction="left" scrollamount="13">          
                <span className="title">Power Interruptions : </span>
                {
                  powerInterruptions
                  .map((i, k) => {

                    return <span key={k}>
                      {moment(i.date.unix).format("MMMM D, YYYY", "Asia/Manila")} ({i.time.start}-{i.time.end})&nbsp;
                      ({                        
                        i.area
                        .map((x, y) => {
                          return (<span key={y} key={y}>{x.name}{((y + 1) === i.area.length)? '' : ','} </span>)
                        })
                      }){((k + 1) === powerInterruptions.length)? '' : ' - '}
                    </span>
                  })
                }
              </marquee>
              <Link to="/news/power-interruption-schedules" className="see-all float-right hover-pointer noselect">See All</Link>
            </p>
          </div>
        : null
      }

      <header className="header-section">
        <div className={(powerInterruptions.length > 0)? 'header-bottomarea with-pi' : 'header-bottomarea'}>
          <div className="container">
            <div className="header-bottom"> 
              <a href="/" className="header-logo"><img src="/assets/images/global/logo-02.png" alt="" width="30"/> </a> 
              <nav className="in-navigation">
                <ul>
                  <li>
                    <Link exact to="/" activeclass="active">Home</Link>
                  </li>
                  <li className="in-dropdown"><a href="#">About Us</a>
                    <ul className="dropdown">
                      <li>
                        <Link to="/about/company-profile" activeclass="active">Company Profile</Link>
                      </li>
                      <li>
                        <Link to="/about/mission-vision" activeclass="active">Vision, Mission, and Core Values</Link>
                      </li>
                      <li>
                        <Link to="/about/pledge" activeclass="active">Cooperative Pledge</Link>
                      </li>
                      <li>
                        <Link to="/about/coverage-area" activeclass="active">Coverage Area</Link>
                      </li>
                      <li>
                        <Link to="/about/organizational-structure" activeclass="active">Organizational Structure</Link>
                      </li>
                      <li>
                        <Link to="/about/bod" activeclass="active">Board of Directors</Link>
                      </li>
                    </ul>
                  </li>
                  {/*
                  <li className="in-dropdown"><a href="#">Services</a>
                    <ul className="dropdown">
                      <li>
                        <Link to="/service/bill-inquiry" activeclass="active">Bill Inquiry</Link>
                      </li>
                      <li>
                        <Link to="/service/new-service-connection" activeclass="active">Application for Electric Service Connection</Link>
                      </li>
                      <li>
                        <Link to="/service/request-complaint" activeclass="active">Request / Complaint Form</Link>
                      </li>
                    </ul>
                  </li>*/}
                  <li className="in-dropdown"><a href="#">News & Advisories</a>
                    <ul className="dropdown">
                      <li>
                        <Link to={`/news/electricity-rates`} activeclass="active">Rates</Link>
                      </li>
                      {
                        newsAndAdv
                        .map((i, k) => {
                          return (<li key={k}>
                              <Link to={`/news/${i.tag}`} activeclass="active">{i.title}</Link>
                            </li>)
                        })
                      }

                    </ul>
                  </li>
                  <li>
                    <Link to="/bids" activeclass="active">Bids</Link>
                  </li>
                  <li>
                    <Link to="/careers" activeclass="active">Careers</Link>
                  </li>
              {/*
                  <li>
                    <Link to="/faq" activeclass="active">FAQ</Link>
                  </li>                 
                   <li>
                    <Link to="/agam-registration" activeclass="active">AGAM Registration</Link>
                  </li>*/}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="mobile-menu-wraper clearfix">
          <div className="container">
            <div className="mobile-menu"></div>
          </div>
        </div>
      </header>
    </>
  );
}

export default withRouter(Header);
