import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function PrivacyPolicy() {
  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Privacy Policy</h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/privacy-policy" activeclass="active">Privacy Policy</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
	<section className="blog-wrapper pt-5">
    	<div className="container">
			<div className="row">

			

				<div className="col-lg-4 col-md-6">
					<div className="item blog_card">
					<div className="post-img"> <img src="/assets/images/home/seal1.jpg" alt=""/>
					</div>
					<div className="post-detail">
						<h5><a href="/assets/images/home/data-privacy.pdf" target="_blank">Data Privacy<br/> <br/></a></h5>
						<div className="post-status">
						<ul>
							<li><i className="fa fa-folder"></i> <span><a href="/assets/images/home/data-privacy.pdf">Main Office</a></span></li>
						</ul>
						</div>
						<div className="description">
						<a href="/assets/images/home/data-privacy.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
					</div>
					</div>
				</div>
	
			</div>
			
		</div>
	</section>
  


      <CTAStrip/>
  	</>
  );
}

export default withRouter(PrivacyPolicy);
