import React, { useEffect } from 'react';

// Local imports

function PageLoader() {
  useEffect(() => {  
    window.$("#preloader").delay(400).animate({
      "opacity" : "0"
      }, 400, function() {
      window.$("#preloader").css("display","none");
    });  
  }, []);
  return (
    <>
      <div id="preloader">
        <div id="loader"></div>
      </div>
    </>
  );
}

export default PageLoader;
