import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function AboutMissionVision() {
  return (
  	<>
      <PageLoader/>      
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>About <span>Us</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about-us" activeclass="active">About</Link>
                  		</li>
			                <li>
                  			<Link exact to="/about/mission-vision" activeclass="active">Vision, Mission, and Core Values</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<section id="about-mission-vision-values" className="inner-page-wrapper about-wrapper">
			  <div className="container">

				  <div className="row mb-5 pb-5">
				  	<div className="col about-text">
			        <p className="text-desc ">PANELCO I as the leading electric service provider in Pangasinan by 2027.</p>
				  	</div>
				  	<div className="col">
			        <p className="text-center">
			        	<img src="/assets/images/about/our-vision.png"/>
			        </p>
			      </div>
				  </div>

			    <div className="row mb-5 pb-5">
				  	<div className="col">
			        <p className="text-center">
			        	<img src="/assets/images/about/our-mission.png"/>
			        </p>
			      </div>
			      <div className="col about-text">
			        <p className="text-desc ">To provide a reliable and sustainable electric service at affordable cost to contribute in uplifting the quality of life in the coverage area</p>
			      </div>
			    </div>

 					<div className="row mb-5 pb-5">
			      <div className="col about-text text-values">
			        <p className="mb-0">Equality</p>
							<p className="mb-0">Integrity</p>
							<p className="mb-0">God Fearing</p>
							<p className="mb-0">Transparency</p>
							<p className="mb-0">Responsibility</p>
							<p className="mb-0">Solidarity</p>
							<p className="mb-0">Service Satisfaction</p>
			      </div>
				  	<div className="col about-text">
			        <p className="text-center">
			        	<img src="/assets/images/about/coop-values.png"/>
			        </p>
			      </div>
			    </div>
			  </div>
			</section>





			<CTAStrip/>
  	</>
  );
}

export default withRouter(AboutMissionVision);
