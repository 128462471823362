import React, { useEffect, useState } from 'react';
import https from 'https';
// Local imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';
import { months } from '../global/var.global';
import Img from '../widgets/Img';

// Live imports
import axios from 'axios';
import parse from 'html-react-parser';
import moment from 'moment';
import Loader from 'react-loader-spinner'

import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function AgamStatus() {
  const [ asOfDate, setAsOfDate ] = useState('');
  const [ regsTotal, setRegsTotal ] = useState(0);
  const [ regsNorth, setRegsNorth ] = useState(0);
  const [ regsSouth, setRegsSouth ] = useState(0);

  useEffect(() => {
    var _d = new Date();
    setAsOfDate(_d.toString().substring(0,24));

      getResults();
    setInterval(() => {
      getResults();
    }, 2000);
  }, []);

  const getResults = async () => {    
    // At request level
    const agent = new https.Agent({  
      rejectUnauthorized: false
    });
    const instance = axios.create({
      httpsAgent: new https.Agent({  
          rejectUnauthorized: false
      })
    });
    await instance.get(route.root.agam + '/all')
      .then(async res => {
      // var _rn = regsNorth;
      // var _rs = regsSouth;
      // var _total = _rn + _rs;
      // setRegsTotal(_total);
      const _res = res.data.res;
    console.log(_res)
      setRegsNorth(_res['qmember_north']);
      setRegsSouth(_res['qmember_south']);
      setRegsTotal(_res['qmember']);
        console.log(_res['qmember_north'])
        console.log(_res['qmember_south'])
        console.log(res.data)
        // setResidentials(res.data.results)
          // setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <PageLoader/>    
      <div className="breadcrumb-wrapper">
        <div className="images-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-heading">
                <h1>AGAM Registration Status</h1>
                <span className="line"></span>
              </div>
              <div className="page-breadcrumb-inner">
                <div className="page-breadcrumb">
                  <div className="breadcrumb-list">
                    <ul>
                        <li>
                          <Link exact to="/" activeclassname="active">Home</Link>
                        </li>
                        <li>
                          <Link exact to="/news">News</Link>
                        </li>
                        <li>
                          <Link exact to="/agam-status" activeclassname="active">AGAM Registration Status</Link>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-wrapper pt-5">
        <div className="container">
          <div className="section_heading text-right">
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3>MCO PER DISTRICT</h3>
              <table className="table">
                <thead>                
                 <tr>
                   <th>DISTRICT</th>
                   <th>MEMBER CONSUMERS</th>
                 </tr>
                </thead>                
                 <tbody>
                 <tr>
                   <td>01 AGNO</td>
                   <td>5,582</td>
                 </tr>
                 <tr>
                   <td>02 ANDA</td>
                   <td>8,106</td>
                 </tr>
                 <tr>
                   <td>03 ALAMINOS</td>
                   <td>19,254</td>
                 </tr>
                 <tr>
                   <td>04 BANI</td>
                   <td>9,706</td>
                 </tr>
                 <tr>
                   <td>05 BOLINAO</td>
                   <td>14,449</td>
                 </tr>
                 <tr>
                   <td>06 BURGOS</td>
                   <td>4,547</td>
                 </tr>
                 <tr>
                   <td>07 DASOL</td>
                   <td>5,704</td>
                 </tr>
                 <tr>
                   <td>08 INFANTA</td>
                   <td>5,009</td>
                 </tr>
                 <tr>
                   <td>09 MABINI</td>
                   <td>4,721</td>
                 </tr>
                 </tbody>
               </table>
             </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h3>RESULTS</h3>
              <p> As of {asOfDate}</p>
              <table className="table">
                <thead>                
                 <tr>
                   <th>TOTAL MCO</th>
                   <th>QUORUM</th>
                   <th>REGISTERED</th>
                 </tr>
                </thead>                

                 <tbody>
                 <tr>
                   <td>77,078</td>
                   <td>Target (<b>3,845</b>)</td>
                   <td>North (<b>{regsNorth}</b>)</td>
                 </tr>
                 <tr>
                   <td> </td>
                   <td>Current (<b>{regsTotal}</b>)</td>
                   <td>South (<b>{regsSouth}</b>)</td>
                 </tr>
                 </tbody>
               </table>
            </div>

          </div>
          
        </div>
      </section>


      <CTAStrip/>
    </>
  );
}

export default withRouter(AgamStatus);