import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter
} from 'react-router-dom';

function Gallery() {
  return (
  	<>
      <PageLoader/>  
      <CTAStrip/>
  	</>
  );
}

export default withRouter(Gallery);
