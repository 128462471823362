import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter,
  NavLink as Link,
  useLocation
} from 'react-router-dom';

function ServiceNewConnection() {

  const [checklist, setChecklist] = useState([
  	{ _id: '001',name: 'Proof of Ownership' },
  	{ _id: '002',name: 'Barangay Certificate' },
  	{ _id: '003',name: 'Valid ID' },
  	{ _id: '004',name: 'Tax Identification Number (W/ Proof)' },
  	{ _id: '005',name: 'Electrical Plan 3 Copies' },
  	{ _id: '006',name: 'Fire Safety Clearance' },
  	{ _id: '007',name: 'Certificate of Occupancy' },
  	{ _id: '008',name: 'Certificate of Final Electrical Inspection (CFEI)' },
  	{ _id: '009',name: 'Electrical Permit' }
  ]);
  const [fullname, setFullname] = useState({})
  const [processChecklists, setProcessChecklists] = useState([])

  const _setChecklists = async (e, el) => {
    let v = e.target.value
    let t = e.target.checked
    const newList = processChecklists.filter((item) => item._id !== el._id); 
    setProcessChecklists(newList);
    if(t){
    	let n = {
    		_id: el._id,
    		file: ''
    	}
      setProcessChecklists(oldArray => [...oldArray, n ]);
    }
    console.log(t, el)
  }

  const _setFullname = async (e, el) => {
    let v = e.target.value
    setFullname(oldArray => ({...oldArray, [el]: v}));
  }

  const _submit = () => {
  	let member = {
  		fullname: fullname,
  		checklists : processChecklists
  	}
  }

  return (
	<>
	  <PageLoader/>
		<div className="breadcrumb-wrapper">
		  <div className="images-overlay"></div>
		  <div className="container">
		    <div className="row">
		      <div className="col-md-12">
		        <div className="page-heading">
		          <h1>New <span>Connection</span></h1>
		          <span className="line"></span>
		        </div>
		        <div className="page-breadcrumb-inner">
		          <div className="page-breadcrumb">
		            <div className="breadcrumb-list">
		              <ul>
		                <li>
		                  <Link exact to="/" className="active">
		                  Home</Link>
		                </li>
		                <li>
		                  <Link exact to="/services" className="active">
		                  Services</Link>
		                </li>
		                <li>
		                  <Link exact to="/service/new-service-connection" className="active">
		                  New Connection</Link>
		                </li>
		              </ul>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>

		<section className="inner-page-wrapper contact-us-wrapper">

      <div className="container">
        <div className="row mb-5">

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

						{/**
					  <img src="/assets/images/application/logo.png" width="100" height="100"></img>
					  <h1>Pangasinan I Electric Cooperative</h1>
					  <p>Brgy San Jose Bani, Pangasinan</p>
					  <p>CWDO No. +63928-502-7969 Telefox No. 6375551 5564</p>
					  <p>Email: panelco_one@yahoo.com</p>
					    <h4 className="text-center">MEMBER'S PROFILE</h4>
						**/}

					  <div className="application-form">
					    <div className="form-col">

						    <div className="row">
						    	<div className="col-sm-8 left-box">
						    		<h3 className="text-center">Member's Profile</h3>
						    		<hr/>
						        <div className="row">
						          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

									      <div className="row-sm-3 mb-3">
									        <label htmlFor="name">Full Name</label>
									        <div className="row">
									          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									        		<input type="text" name="name" className="form-control mb-2" placeholder="Last Name"/>
									          </div>
									          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									        		<input type="text" name="name" className="form-control mb-2" placeholder="First Name, Suffix"/>
									          </div>
									          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									        		<input type="text" name="name" className="form-control mb-2" placeholder="Middle Name"/>
									          </div>
									        </div>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="address">Address <small>House Number or Lot/Blk/Phase, Street Name, Barangay Name, Town or City Name, Zip code</small></label>
									        <textarea rows="6" className="form-control" placeholder="House Number or Lot/Blk/Phase, Street Name, Barangay Name, Town or City Name, Zip code"></textarea>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="date-of-birth">Date of Birth</label>
									        <input type="text" name="date-of-birth" className="form-control" placeholder="ex. 01/31/1990"/>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="age">Age</label>
									        <input type="text" name="age" className="form-control" placeholder="ex. 20"/>
									      </div>

									      <div className="row-sm-3 mb-2">
									        <label htmlFor="gender">Gender</label>
									        <select className="form-control">
									        	<option>Select Gender</option>
									        	<option>Male</option>
									        	<option>Female</option>
									        </select>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="civil-status">Civil Status</label>
									        <select className="form-control">
									        	<option>Select Civil Status</option>
									        	<option>Single</option>
									        	<option>Married</option>
									        	<option>Divorced</option>
									        	<option>Widowed</option>
									        </select>
									      </div>

						          </div>

						          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

									      <div className="row-sm-3 mb-2">
									        <label htmlFor="contact-number">Contact Number <i><small>(If more than 1, put comma (,) after each number)</small></i> </label>
									        <textarea rows="4" className="form-control" placeholder="ex. 0912-345-67890, 0912-345-67891"></textarea>
									      </div>						          
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="nearest-account-number">Nearest Account Number</label>
									        <input type="text" name="nearest-account-number" className="form-control"></input>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="educational-attainment">Educational Attainment</label>
									        <select className="form-control">
									        	<option>Select Educational Attainment</option>
									        	<option>Primary</option>
									        	<option>Secondary</option>
									        	<option>Tertiary</option>
									        	<option>Vocational</option>
									        </select>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="occupation">Occupation/Source of Income</label>
									        <input type="text" name="occupation" className="form-control"></input>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="number-of-dependets">Number of Dependent/s</label>
									        <input type="text" name="number-of-dependets" className="form-control"></input>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="religion">Religion/Social Affiliation</label>
									        <input type="text" name="religion" className="form-control"></input>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="annual-income">Annual Income</label>
									        <input type="text" name="annual-income" className="form-control"></input>
									      </div>
									      <div className="row-sm-3 mb-2">
									        <label htmlFor="tax">TAX Identification Number</label>
									        <input type="text" name="tax" className="form-control"></input>
									      </div>

						          </div>
						        </div>
							    </div>
						    	<div className="col-sm-4">						    	
										<div className="row">
				 						<div className="">
											<div className="col-sm-12">
												<p className="mb-0">Checklist on Processing</p>
											</div>
											<div className="col-sm-12">
												{
													checklist
													.map((i, k) => {
														return (<div className="custom-control custom-checkbox" key={k}>
															  <input type="checkbox" className="custom-control-input" id={`check${k}`} defaultValue={i} onChange={(e) => _setChecklists(e, i)}/>
															  <label className="custom-control-label" htmlFor={`check${k}`}>{i.name}</label>
															</div>)
													})
												}
											</div>
										</div>
										</div>
							  	</div>

							  </div>

					    </div>
					  </div>

					</div>		
				</div>		
			</div>		

		
			<div className="container">
			  <header>
			    <h5 className="text-left">RESIDENTIAL</h5>
			  </header>
			  <header>
			    <h4 className="text-center">WIRING JOB ORDER & HOUSEWIRING ESTIMATE</h4>
			  </header>
			  <form>
			    <div className="form-col">
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="electrician">Electrician :</label>
			        <input type="text" name="electrician" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="wjo-no">WJO NO :</label>
			        <input type="text" name="wjo-no" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="name">Name/Member :</label>
			        <input type="text" name="name" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-5">
			        <label htmlFor="address">Address :</label>
			        <input type="text" name="address" className="form-control"></input>
			      </div>
			      <table className="table table-hover">
			        <thead>
			          <tr>
			            <th scope="col">QTY</th>
			            <th scope="col">ITEM</th>
			            <th scope="col">NO. OF EXISTING OUTLET</th>
			            <th scope="col">UNIT COST MATERIAL LABOR</th>
			            <th scope="col">INSPECTION</th>
			            <th scope="col">TOTAL</th>
			          </tr>
			        </thead>
			        <tbody>
			          <tr>
			            <th scope="row"></th>
			            <td>KWH Meter</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>EDMI MK 61/EDMI MK 31</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Hycrimp YHO 100,comp tap</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Hycrimp YHO 150,comp tap</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Screw Insulator</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Service grip dead end #6</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Meter Base class 100</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Meter Box, service drop</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Service drop acc. (1 SET)</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Padlock Seal</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Duplex Wire #6 (FREE 30m)</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Plus:</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Membership Fee</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Notarial Fee</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Processing Fee</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>ID Fee</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>VAT (12%)</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			          <tr>
			            <th scope="row"></th>
			            <td>Grand Total</td>
			            <td></td>
			            <td></td>
			            <td></td>
			            <td></td>
			          </tr>
			        </tbody>
			      </table>
			      <h5 className="text-center">AGREEMENT</h5>
			      <p>Siak	makin balay ikarik nga daytoy L.O. C.O. ti naikabit iti balay ko sigun ti pinirmaan ti Master Electrician, Electrical Engineer, ken pinirmaak. Ikarik a no agnayonak  iti  silaw wenno saksakan ti balay ko ipakaammok babaen ti panagbayad ko ti inspeksyon iti innayon ko nga outlet (L.O.-C.O.) iti Cooperatiba. No aglabsingak iti daytoy nga Inkarik isu ti pagapuan ti panakadiskonekta ti serbisio ti kuryente ti balay ko wenno pakamultaak/pagpresuak babaen ti P.D. 401 nga inadaptar ti Cooperatiba babaen ti Policy No.2-7 idi Nov. 19, 1976.
			      </p>
			      <p className="text-right">Applicant/Houseowner-Date</p>
			      <p>Siak	Electrician, ikarik nga no agnayonak ti silaw wenno saksakan iti makinbalay ket irehistrok ditoy opisina ti PANELCO sakbay maikabit ko ti ipanayonna. No aglabsingak, isu ti pakaikkatak kas accredited Electrician ti PANELCO.
			      </p>
			      <p className="text-right">Electrician-Date</p>
			      <p className="text-left">Processor/Estimator</p>
			    </div>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">APPLICATION FOR MEMBERSHIP AND FOR ELECTRIC SERVICE</h4>
			  </header>
			  <form>
			    <p>
			      The undersigned (hereinafter called the "Applicant") hereby applies for membership and agrees to purchase electric energy from the Pangasinan I Electric Cooperative (the hereinafter called the "Cooperative") upon the following terms and conditions:
			    </p>
			    <p>
			      1.	The applicant will pay the coop the sum of P100.00 and if this is accepted by the cooperative will constitute the applicant's membership fee;
			    </p>
			    <p>
			      2.	The applicant will when electric energy becomes available will purchase from the cooperative all the electric energy to be used on the premises described below and shall pay therefore monthly rates to be determined from time to time in accordance with the bylaws of the cooperative;
			    </p>
			    <p>
			      3.	The applicant will cause his/her premises to be wired in accordance with the wiring application approved by the cooperative;
			    </p>
			    <p>
			      4. The applicant will comply with and is bound by the provisions of the charter and bylaws of the coop and such rules and regulations as may from time to time be adopted by the coop;
			    </p>
			    <p>
			      5: The applicant by paying the membership fee and becoming a member assumes no personal liability or responsibility to any debt or liability of the coop and it is expressly understood that under the law his private property is exempt from execution of any debt or liability;
			    </p>
			    <p>
			      6.	The applicant shall pledge to subscribe to at least ten (10) shares of the cooperative and shall initially pay at least two (2) shares upon application and the balance to be divided and paid in equal installments for two (2) years at Php80.00 per month, and the value of one (1) share is Two Hundred Forty Pesos (Php240.00) but shall not subscribe to more than ten percent (10%) of the subscribed capital stock of the cooperative;
			    </p>
			    <p>
			      7.	The undersigned will grant to the coop at its request free right-of-way (ROW) on his/her land, the necessary rights and easements to construct, operate, replace, repair and perpetually maintain on the property owned by the undersigned its lines for the transmission or distribution of electric energy, and will execute and deliver to the coop any conveyance, grant an instrument which the coop shall deem necessary or convenient for said purpose or any of them. All lines of the coop and switches, meters and other appliances and equipment on said property shall have the right of access to said property to operate, maintain or relocate its facilities;
			    </p>
			    <p>
			      8.	The cooperative shall not be liable for damage to the applicant for failure to supply electricity to said premises under any condition;
			    </p>
			    <p>
			      9.	The acceptance of the application by the coop shall constitute an agreement between the applicant and cooperative and the contract for electric service shall continue in force for one year from the date the service is made available by the cooperative · to the applicant thereat as long as the applicant has need for any electric service of the said premises.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>Date</p>
			        <p>(Witness)</p>
			        <p>(Board Action)</p>
			        <p>O.R No./MC No.</p>
			        <p>HWI No.</p>
			      </div>
			      <div className="col">
			        <p>Signature</p>
			        <p>(Printed Name)</p>
			        <p>Com. Tax. Cert. No.</p>
			        <p>Issued on:</p>
			        <p>Issued at:</p>
			        <p>House No./Street/Barangay</p>
			        <p>Municipality</p>
			        <p>Contact Number</p>
			      </div>
			    </div>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">METERED SERVICE CONTRACT</h4>
			  </header>
			  <form>
			    <div className="form-row">
			      <div className="col-sm-6 mb-3">
			        <input type="text" className="form-control" id="consumer-name" placeholder="Consumer Name"/>
			      </div>
			      <div className="col-sm-6 mb-3">
			        <input type="text" className="form-control" id="address" placeholder="Address"/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="bill-deposit" placeholder="Bill Deposit"/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="type-of-meter" placeholder="Type Of Meter"/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="date" placeholder="Date"/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="or-no" placeholder="O.R No."/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="service-connection-no" placeholder="Service Connection No."/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="watts" placeholder="Watts"/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="hp" placeholder="HP"/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="connected-loads" placeholder="Connected Loads"/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="rate-sch" placeholder="Rate SCH."/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="permit-no" placeholder="Permit No."/>
			      </div>
			    </div>
			    <p>This AGREEMENT entered into by and between the PANGASINAN ELECTRIC COOPERATIVE, INC., hereinafter referred to as the COOPERATIVE and the CONSUMER named above.</p>
			    <h5 className="text-center">WITNESSETH:</h5>
			    <p>1.	The COOPERATIVE agrees to furnish electric service to the premises occupied by the consumer at the address stated above in accordance to the load, at the rate stipulated in the cooperative's applicable rate schedule which is made an integral part hereof, provided that the wiring installation and electrical equipment, apparatus and devices in said premises are and remain in satisfactory conditions as verified by the Inspection Section of the Cooperative and has a valid Electrical Permit or certificate from the government office concerned and provided further that the secondary distribution facilities and capacity are available at the consumer's premises.
			    </p>
			    <p>2.	The Cooperative shall not in any way be liable to damages that may occur arising from increase in load and additional installation of the protective devices, lighting and convenience outlets of the consumer which is not properly reported to government agencies and its instrumentalities and duly approved by cooperative.
			    </p>
			    <p>3.	The Cooperative will designate the point of service connection. The service entrance conduit shall be in one exposed continuous run on the outside building wall from the service drop terminal to the meter, except when the meter is installed o·n the pole in which case the service entrance may be concealed. For the provision of the Philippine Electrical Code may be allowed by special arrangement.
			    </p>
			    <p>4.	The Cooperative shall have the right, if necessary, to construct its poles, lines and circuits and to place its transformers, apparatus on their property or points convenient for such purpose. The customer shall allow the distribution utility to use a suitable space for the installation of necessary metering equipment in order that such equipment will be protected from damage by the elements, or through the negligence or deliberate acts of any person or persons. When the customer desires delivery of energy for separate buildings or premises, a separate contract shall be required for each point of delivery.
			    </p>
			    <p>5.	The Cooperative will use a reasonable diligence in furnishing a regular and uninterrupted supply of energy, but in case such supply should be interrupted or failed by force majeure, the public enemy, accidents, strikes, legal process, Provincial or Municipal interferences, breakdowns or injury to the machinery of distribution lines of the cooperative or extraordinary repairs, the Cooperative shall not be liable for damages. The Cooperative shall not be liable to the consumer for any loss, injury or damages resulting from the customers use of its equipment or from the use of energy of the cooperative's wires with consumer's wires and appliances.
			    </p>
			    <p>6.	The Cooperative shall record and promptly investigate all complains referred to them concerning their services. The distribution utility must furnish the complainant a report of the action/s taken thereon within the period stated in the distribution utility's Compliance Plan as provided for in the Philippine Distribution Code. In the absence of such plan, the report must be made within fifteen (15) days from receipt of the complaint.
			    </p>
			    <p>7.	The Cooperative shall if the said customer is not the owner of the premises sought to be energized, shall be required to submit an undertaking from the owner of the premises that the said owner shall be jointly and severally liable with the applicant for any unpaid regular monthly bills incurred by the applicant after leaving the premises, in the absence of or insufficiency of the bill deposit.
			    </p>
			    <p>8.	The customer shall guarantee and/or assume the responsibility of paying all electric bill and other arrearages of multiple house wiring connections in securing clearance for reconnection.
			    </p>
			    <p>9.	The customer agrees to pay his monthly bill at the cooperative's office within nine (9) days after receipt thereof. Failure of the customer pay the bill within the period herein fixed, the customer shall suffer penalty charge for the late payment, depending on the kilowatt hour used.	•
			    </p>
			    <p>10.	The Cooperative shall exempt all residential consumers from payment of METER DEPOSIT. Further, the labor cost for connecting the distribution utility to the connection point shall be free of charge.
			    </p>
			    <p>11.	The Cooperative shall require a BILL DEPOSIT from customers of new and/or additional service equivalent to the estimated billing for one-month guarantee payment of bills. Provided that after one (1) year and every year thereafter, when the actual average monthly bills are more or less than the initial bill deposit, such deposit shall be correspondingly increased/decreased to approximate said billing. A customer who has paid his electric bills on or before its due date for three (3) consecutive years, may, however, demand for the full refund of the deposit even prior to the termination of his service upon application to the distribution utility provided all bills have been paid.
			    </p>
			    <p>12.	The Cooperative shall provide all electric meters to be installed or placed in service unless it has been tested, certified and sealed by the ERC. All watt-hour meters regardless of make and type before being placed in service must be adjusted to as close as possible to the condition of zero error.
			    </p>
			    <p>13.	The Cooperative, shall not, except in case of emergency, disconnect its service connection to the consumer's premises without at least 48 hours previous written notice, served upon the consumer by leaving a copy of same at his shop, office or usual place of abode, with any member of the family; servant or agent.
			    </p>
			    <p>14.	The Cooperative reserves the right to disconnect the service for any of the following reasons: (a) illegal use of electricity under R.A. 7832; (b) For non-payment of bills; (c) For repairs; (d) For want of supply or of distribution capacity; (e) request of the registered customers on voluntary disconnection based on justifiable reason; (f) upon lawful orders of government agencies and/or the courts; (g) when the cooperative and public safety so require; and (h) the customer to totally refrain from giving or allowing connection from his/her premises to another house, apartment, dwelling, etc. or extending a connection across the street.
			    </p>
			    <p>15.	The Cooperative shall when at a time of disconnection is to be made, the customer tenders payment of the unpaid bill to the agent or employee of the distribution utility shall desist from disconnecting the service to allow the customer to pay his bills within 24 hours; provided however, that the customer can only invoke provision once for the same unpaid bill.
			    </p>
			    <p>16.	The customer agrees to maintain the_ wiring installation and the service drop of the premises in proper and safe condition including its right of way through the life of the cooperative. The customer should be liable for any untoward incident that may occur on any damage to dilapidated condition of its service drop and accessories.
			    </p>
			    <p>17. The customer shall allow the employees and/or representative of the distribution utility to enter their premises for the purpose of 
			      inspecting, installing, reading, testing, repairing, maintaining, removing, replacing, or otherwise disposing of its apparatus and property 
			      and/or removing distribution utility's entire property in the event of termination of the electricity service contract: and for disconnection of 
			      service for non-payment of bills or violation of contract (VOC). Provided, however that only authorized employee and/or representatives 
			      of the distribution utility with proper identification cards shall be allowed to make any external adjustment of meter or any internal or 
			      external adjustment of any other pieces of apparatus owned by the distribution utility.
			    </p>
			    <p>18. The customer agrees that he/she or his/her authorized representative will witness the testing of the kilowatt hour meter found to be 
			      defective, removed/disconnected within the meter laboratory of the cooperative on the date and time specified.
			    </p>
			    <p>19. The Cooperative shall test once every two (2) years, free of charge, the accuracy of the meter installed in his premises making use of a 
			      meter standard duly tested and sealed by the ERC. If the customer requested for meter testing more than once every (2) years and the 
			      meter being tested is found to be within the tolerable limit is provided for in Article 9 hereof the Coop shall assess the customer a testing 
			      fee based on the testing charged by ERC. A written report showing the result of such test shall be furnished the customer. (Refer to BR 
			      No. 93-63. Service Charges.)
			    </p>
			    <p>20.  In the event that the meter in service is found to have an average error or more than the tolerance of plus two percent, the customer is 
			      entitled to a refund, fora maximum period of six (6) months prior to the date of discovery, to be applied to the customer's future billings.
			    </p>
			    <p>21. In the event of stoppage or failure of any meter to register the full amount of the energy consumed, the customer shall be billed for such 
			      period on an estimated consumption based on the load or upon his use of energy in a similar period of like use.
			    </p>
			    <p>22.  In the event the electric meter is installed in the customer's premises fails to register the actual amount of the energy used as result of 
			      the tampering of the meter and/or the installation of other illegal devices, the cooperative, shall adjust its bills to reflect the correct 
			      amount of the electric energy used in accordance to R.A. 7832. If the consumer fails to pay his adjusted bill, the cooperative without 
			      prejudice to the consumers criminal liability, is authorized to suspend its service to said customer. Such suspension of service shall not 
			      terminate this contract.
			    </p>
			    <p>23. The customer agrees to totally refrain from using or allowing connection from his/her premises of inductive motors. equipment welding 
			      machines without the required individual distribution transformer as specified by the cooperative. The cooperative shall have the right to 
			      disconnect the electric service of the customer without prior notice for his/her failure to provide necessary transformer.
			    </p>
			    <p>24. The customer shall allow the cooperative's personnel to conduct right of way clearing based on the required distance from the 
			      distribution line on all its properties including those not adjoining his connection of electric service. Likewise, the customer shall abide by 
			      the provision of the Provincial Ordinance No. 93-2000 by prohibiting construction of structures, planting of trees and other plant species 
			      having a potential growth in height of at least 17 feet and the burning of waste, grass, etc. underneath the distribution line and based on 
			      the clearing policy of the cooperative.
			    </p>
			    <p>25. The applicant shall pledge to subscribe to at least ten (10) shares of the cooperative and shall initially pay at least two (2) shares upon 
			      application and the balance to be divided and paid in equal installments for two (2) years at Php80.00 per month, and the value of one (1) 
			      share is Two hundred Forty Pesos (Php240.00) but shall not subscribe to more than ten percent (10`)/0) of the subscribed capital stock of 
			      the cooperative;
			    </p>
			    <p>26. Any violation of the Provision of his contract including Memorandum of Agreement signed between contracting parties shall give the 
			      cooperative the right to disconnect electric service upon demand without any need for judicial orders or any other authority from 
			      whomsoever.
			    </p>
			    <p>27.  It is agreed that all terms and stipulation made in relation to the electric service are contained in this contract and that no representation 
			      or agreement be made by the cooperative's officers and agents shall be binding upon the Board of Directors of this cooperative and by 
			      the proper governmental agency concerned is deemed part of this contract and may give rise to rights and obligations of the parties 
			      hereto to the extent applicable.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>ACCEPTED ON (Date)</p>
			        <p>Pangasinan I Electric Cooperative</p>
			        <p>By: Dionisio O. Opolento, Jr.</p>
			        <p>General Manager (or any Authorized Representative)</p>
			      </div>
			      <div className="col">
			        <p>Consumer's Printed Name/Signature</p>
			        <p>Res. Cert. No.</p>
			        <p>Issued on</p>
			        <p>Issued at:</p>
			        <p>Building/House Owner's Signature</p>
			      </div>
			    </div>
			    <h5 className="text-center">WITNESSES</h5>
			    <div className="row">
			      <div className="col">
			        <p>(Name)</p>
			        <p>Republic of the Philippines)</p>
			        <p>Province of Pangasinan) S.S.</p>
			        <p>City of Alaminos</p>
			      </div>
			      <div className="col">
			        <p>(Name)</p>
			      </div>
			    </div>
			    <h5 className="text-center">ACKNOWLEDGEMENT</h5>
			    <p>	Before me, a Notary Public for and in	(Fill Up), this (Fill Up) day of (Fill Up)
			      personally, appeared DIONISIO 0. OPOLENTO, JR., General Manager, Pangasinan I Electric Cooperative with SSS No. 02-0612326-0 and (Fill Up)
			      with Community Tax Certificate No. (Fill Up), issued on (Fill Up) at (Fill Up)
			      known to me and to me known to be the same persons who executed the foregoing
			      instrument, consisting of (Fill Up) pages, including the pages where the Acknowledgment is written, all pages signed by both
			      parties and their instrument witnesses, and they acknowledged before me that the same are their free and voluntary act and deed and that of the company they represent.
			    </p>
			    <div className="col">
			      <p>WITNESS MY HAND AND SEAL, on the date and place above written</p>
			      <p>Doc. No. (Fill Up)</p>
			      <p>Page No. (Fill Up)</p>
			      <p>Book No. (Fill Up)</p>
			      <p>Series of (Fill Up)</p>
			    </div>
			  </form>
			</div>
		
		</section>
	  <CTAStrip/>
	</>
  );
}

export default withRouter(ServiceNewConnection);
