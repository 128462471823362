import React, { useEffect, useState } from 'react';

// Local imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';
import { route } from '../global/route.global';
import { months } from '../global/var.global';
import Img from '../widgets/Img';

// Live imports
import axios from 'axios';
import parse from 'html-react-parser';
import moment from 'moment';
import Loader from 'react-loader-spinner'

import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function NewsEventsActivities() {
  const [searchOpen,setSearchOpen] = React.useState(false);
  const [ lists, setLists ] = useState([]);
  const [isLoading,setIsLoading] = React.useState(true);

  useEffect(() => {
    getLists();
  }, []);

  const getLists = async () => {
    await axios.get(route.root.newsadvisory + '/content/events-and-activities')
      .then(async res => {
        // console.log(res.data)
        setLists(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  }

  return (
    <>
      <PageLoader/>    
      <div className="breadcrumb-wrapper">
        <div className="images-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-heading">
                <h1>Events and Activities</h1>
                <span className="line"></span>
              </div>
              <div className="page-breadcrumb-inner">
                <div className="page-breadcrumb">
                  <div className="breadcrumb-list">
                    <ul>
                        <li>
                          <Link exact to="/" activeclassname="active">Home</Link>
                        </li>
                        <li>
                          <Link exact to="/news">News</Link>
                        </li>
                        <li>
                          <Link exact to="/news/advisories" activeclassname="active">Events and Activities</Link>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-wrapper pt-5">
        <div className="container">
          <div className="section_heading text-right">
            <p>
              <span className="mr-3"><i className="fa fa-filter"></i> Filter by: <span>Month</span> </span>  
              {(searchOpen)? <input type="text" className="mr-2 searchBox form-control" placeholder="Type here..."/> : null} 
              <span className="hover-pointer" onClick={toggleSearch}>
                {(searchOpen)? <i className="fa fa-close mr-2"></i> : <i className="fa fa-search mr-2"></i>}
                 Search </span> 
            </p>
          </div>

          <div className="row">

            {
              (isLoading)?
                <div className="container">
                  <div className="text-center"><Loader type="TailSpin" color="#f10d08" height={70} width={70} timeout={9000}/><h6>LOADING...</h6></div>
                </div>
              : null
            }

            {
              lists
              .map((i, k) => {
                var d = new Date(i.createdAt);
                console.log(i.images[0])
                return(<div className="col-lg-4 col-md-6" key={k}>
                    <div className="item blog_card">
                      <div className="post-img">
                      {
                        (i.images.length > 0)?
                          <Img src={`data:image/png;base64,` + Buffer.from(i.images[0]).toString('base64')} alt=""/>
                        : <Img src="/assets/images/home/blog1.jpg" alt=""/>
                      }
                      </div>
                      <div className="post-detail">
                        <h5 title={i.title}>{(i.title.length > 20)? i.title.substring(0, 20) + '...' : i.title}</h5>
                        <div className="post-status">
                          <ul>
                            {/**<li><i className="fa fa-tags"></i> <span><a href="/blog/blog-title">Holiday</a></span></li>**/}
                            <li><i className="fa fa-calendar"></i> <span>{moment(d).format("MMMM D, YYYY")}</span></li>
                          </ul>
                        </div>
                        <div className="description">
                          <div className="box-of">{parse(i.body)}</div>
                          <Link to={`/news/advisory/${i._id}`} className="theme-button-one bttn color-one" data-text="Read More"><span>Continue</span></Link> </div>
                      </div>
                    </div>
                  </div>)
              })
            }

          </div>
          
        </div>
      </section>


      <CTAStrip/>
    </>
  );
}

export default withRouter(NewsEventsActivities);