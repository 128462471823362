import React from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  withRouter,
  NavLink as Link
} from 'react-router-dom';

function ServiceNewConnection() {
  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Requests <span>and Complaints</span></h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
			                  <Link exact to="/" className="active">
			                  Home</Link>
			                </li>
			                <li>
			                  <Link exact to="/services" className="active">
			                  Services</Link>
			                </li>
			                <li>
			                  <Link exact to="/service/request-complaint" className="active">
			                  Requests and Complaints</Link>
			                </li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<header>
			  <img src="/assets/images/application/logo.png" width="100" height="100"></img>
			  <h1>Pangasinan I Electric Cooperative</h1>
			  <p>Brgy San Jose Bani, Pangasinan</p>
			  <p>CWDO No. +63928-502-7969 Telefox No. 6375551 5564</p>
			  <p>Email: panelco_one@yahoo.com</p>
			</header>
			<div className="container">
			  <header>
			    <h4 className="text-center">APPLICATION FOR TRANSFER/CHANGE OF NAME</h4>
			  </header>
			  <form>
			    <p>(Date)</p>
			    <p>Mr. Dionisio O. Opolento Jr.</p>
			    <p>General Manager PANELCO I</p>
			    <p>San Jose, Bani, Pangasinan</p>
			    <p>Dear Sir:</p>
			    <p>
			      May I request the transfer to my name the electric billing and/or meter presently under the name of (Fill Up) located at (Fill Up), Pangasinan, with account number (Fill Up) for the reason that I am now the actual occupant of the said account number household/business establishment.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>Very truly yours,</p>
			        <p>_________________</p>
			        <p>Signature over printed name of the applicant/New Occupant</p>
			        <p>Recommending Approval:</p>
			        <p>ALICE N. OLIVO</p>
			        <p>ISD Manager</p>
			      </div>
			      <div className="col">
			        <p>With my conformity:</p>
			        <p>___________________</p>
			        <p>Signature over Printed Name of the Former Owner/Occupant</p>
			        <p>Approved:</p>
			        <p>DIONISIO O. OPOLENTO JR.</p>
			        <p>General Manager</p>
			      </div>
			    </div>
			    <p>________________________________________________________________________________________________________________________________________________________________</p>
			    <p>Republic of the Philippine</p>
			    <p>Province of Pangasinan</p>
			    <p>Municipality/City of _______</p>
			    <p>Barangay _______</p>
			    <header>
			      <h4 className="text-center">OFFICE OF THE BARANGAY CAPTAIN</h4>
			      <h4 className="text-center">CERTIFICATION</h4>
			    </header>
			    <p>To Whom It May Concern:</p>
			    <p>
			      This is to certify that Mr./Ms.(Fill Up)a resident of this barangay is the actual occupant of the household/building formerly owned/occupied by Mr./Ms.(Fill Up)under account number(Fill Up)
			    </p>
			    <p>
			      This certification is issued this (Fill up) day of (Fill Up) 20 (Fill Up) in connection to the request of Mr./Ms. (Fill Up) for the transfer/change name.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>Certified by:</p>
			        <p>_________________</p>
			        <p>Signature over Printed Name of Punong Barangay</p>
			      </div>
			      <div className="col">
			        <p>Witness:</p>
			        <p>___________________</p>
			        <p>Signature over Printed Name</p>
			      </div>
			    </div>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">AGREEMENT OF TRANSFER OF CDA SHARE CAPITAL</h4>
			  </header>
			  <form>
			    <p>KNOW ALL MEN BY THESE PRESENTS:</p>
			    <p>
			      I/WE,(Fill Up), Filipino, of legal age, married/single and presently residing at (Fill Up) (complete address with house street number) hereinafter to be referred to as TRANSFEROR and
			    </p>
			    <p>
			      I,(Fill Up), Filipino, of legal age, married/single and presently residing at (Fill Up)	(complete address with house street number) hereinafter to be referred to as TRANSFEREES witnessed as follows:
			    </p>
			    <p>
			      1. That the TRANSFEROR is a bonafide ownner of a housewiring duly approved by PANELCO I presently installed at (Fill Up) (complete address with house street-number) under Account Number (Fill Up)	and Meter Number (Fill Up). 
			    </p>
			    <p>
			      2. That the TRANSFEROR is transferring his/hershare capital to the above mentioned TRANSFEREE;
			    </p>
			    <p>
			      3. That the TRANSFEREE hereby acknowledges the transfer of share capital from the TRANSFEROR;
			    </p>
			    <p>
			      4. That herein TRANSFEROR and TRANSFEREE hereby affirm that all the facts stated herein are
			      true and correct to the best of our knowledge.
			    </p>
			    <p>
			      IN WITNESS WHEREOF, We have hereunto set our hands this (Fill Up)	day of (Fill Up) 20 (Fill Up)	at (Fill Up), Pangasinan.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>_________________</p>
			        <p>(Print Name & Sign) Transferor/s</p>
			        <p>Valid ID No.__________</p>
			      </div>
			      <div className="col">
			        <p>___________________</p>
			        <p>(Print Name & Sign) Transferee/s</p>
			        <p>Valid ID No.__________</p>
			      </div>
			    </div>
			    <p>
			      SUBSCRIBED AND SWORN to before me this (Fill Up) day of (Fill Up)	20 (Fill Up)	at (Fill Up), Pangasinan.
			    </p>
			    <p className="text-right">_____________ </p>
			    <p className="text-right">Notary Public</p>
			    <p>Doc. No.______</p>
			    <p>Page No.______</p>
			    <p>Book No.______</p>
			    <p>Series of_____</p>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">MEMBER'S PROFILE</h4>
			  </header>
			  <form>
			    <div className="form-col">
			      <div className="row-sm-3 mb-3">
			        <label htmlFor="name">Name :</label>
			        <input type="text" name="name" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="address">Address :</label>
			        <input type="text" name="address" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="date-of-birth">Date of Birth :</label>
			        <input type="text" name="date-of-birth" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="age">Age :</label>
			        <input type="text" name="age" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="gender">Gender :</label>
			        <input type="text" name="gender" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="civil-status">Civil Status :</label>
			        <input type="text" name="civil-status" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="contact-number">Contact Number :</label>
			        <input type="text" name="contact-number" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="nearest-account-number">Nearest Account Number :</label>
			        <input type="text" name="nearest-account-number" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="educational-attainment">Educational Attainment :</label>
			        <input type="text" name="educational-attainment" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="occupation">Occupation/Source of Income :</label>
			        <input type="text" name="occupation" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="number-of-dependets">Number of Dependent/s :</label>
			        <input type="text" name="number-of-dependets" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="religion">Religion/Social Affiliation :</label>
			        <input type="text" name="religion" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="annual-income">Annual Income :</label>
			        <input type="text" name="annual-income" className="form-control"></input>
			      </div>
			      <div className="row-sm-3 mb-2">
			        <label htmlFor="tax">TAX Identification Number :</label>
			        <input type="text" name="tax" className="form-control"></input>
			      </div>
			    </div>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">METERED SERVICE CONTRACT</h4>
			  </header>
			  <form>
			    <div className="form-row">
			      <div className="col-sm-6 mb-3">
			        <input type="text" className="form-control" id="consumer-name" placeholder="Consumer Name" defaultValue=""/>
			      </div>
			      <div className="col-sm-6 mb-3">
			        <input type="text" className="form-control" id="address" placeholder="Address" defaultValue=""/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="bill-deposit" placeholder="Bill Deposit" defaultValue=""/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="type-of-meter" placeholder="Type Of Meter" defaultValue=""/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="date" placeholder="Date" defaultValue=""/>
			      </div>
			      <div className="col-sm-3 mb-3">
			        <input type="text" className="form-control" id="or-no" placeholder="O.R No." defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="service-connection-no" placeholder="Service Connection No." defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="watts" placeholder="Watts" defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="hp" placeholder="HP" defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="connected-loads" placeholder="Connected Loads" defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="rate-sch" placeholder="Rate SCH." defaultValue=""/>
			      </div>
			      <div className="col-sm-4 mb-3">
			        <input type="text" className="form-control" id="permit-no" placeholder="Permit No." defaultValue=""/>
			      </div>
			    </div>
			    <p>This AGREEMENT entered into by and between the PANGASINAN ELECTRIC COOPERATIVE, INC., hereinafter referred to as the COOPERATIVE and the CONSUMER named above.</p>
			    <h5 className="text-center">WITNESSETH:</h5>
			    <p>1.	The COOPERATIVE agrees to furnish electric service to the premises occupied by the consumer at the address stated above in accordance to the load, at the rate stipulated in the cooperative's applicable rate schedule which is made an integral part hereof, provided that the wiring installation and electrical equipment, apparatus and devices in said premises are and remain in satisfactory conditions as verified by the Inspection Section of the Cooperative and has a valid Electrical Permit or certificate from the government office concerned and provided further that the secondary distribution facilities and capacity are available at the consumer's premises.
			    </p>
			    <p>2.	The Cooperative shall not in any way be liable to damages that may occur arising from increase in load and additional installation of the protective devices, lighting and convenience outlets of the consumer which is not properly reported to government agencies and its instrumentalities and duly approved by cooperative.
			    </p>
			    <p>3.	The Cooperative will designate the point of service connection. The service entrance conduit shall be in one exposed continuous run on the outside building wall from the service drop terminal to the meter, except when the meter is installed o·n the pole in which case the service entrance may be concealed. For the provision of the Philippine Electrical Code may be allowed by special arrangement.
			    </p>
			    <p>4.	The Cooperative shall have the right, if necessary, to construct its poles, lines and circuits and to place its transformers, apparatus on their property or points convenient for such purpose. The customer shall allow the distribution utility to use a suitable space for the installation of necessary metering equipment in order that such equipment will be protected from damage by the elements, or through the negligence or deliberate acts of any person or persons. When the customer desires delivery of energy for separate buildings or premises, a separate contract shall be required for each point of delivery.
			    </p>
			    <p>5.	The Cooperative will use a reasonable diligence in furnishing a regular and uninterrupted supply of energy, but in case such supply should be interrupted or failed by force majeure, the public enemy, accidents, strikes, legal process, Provincial or Municipal interferences, breakdowns or injury to the machinery of distribution lines of the cooperative or extraordinary repairs, the Cooperative shall not be liable for damages. The Cooperative shall not be liable to the consumer for any loss, injury or damages resulting from the customers use of its equipment or from the use of energy of the cooperative's wires with consumer's wires and appliances.
			    </p>
			    <p>6.	The Cooperative shall record and promptly investigate all complains referred to them concerning their services. The distribution utility must furnish the complainant a report of the action/s taken thereon within the period stated in the distribution utility's Compliance Plan as provided for in the Philippine Distribution Code. In the absence of such plan, the report must be made within fifteen (15) days from receipt of the complaint.
			    </p>
			    <p>7.	The Cooperative shall if the said customer is not the owner of the premises sought to be energized, shall be required to submit an undertaking from the owner of the premises that the said owner shall be jointly and severally liable with the applicant for any unpaid regular monthly bills incurred by the applicant after leaving the premises, in the absence of or insufficiency of the bill deposit.
			    </p>
			    <p>8.	The customer shall guarantee and/or assume the responsibility of paying all electric bill and other arrearages of multiple house wiring connections in securing clearance for reconnection.
			    </p>
			    <p>9.	The customer agrees to pay his monthly bill at the cooperative's office within nine (9) days after receipt thereof. Failure of the customer pay the bill within the period herein fixed, the customer shall suffer penalty charge for the late payment, depending on the kilowatt hour used.	•
			    </p>
			    <p>10.	The Cooperative shall exempt all residential consumers from payment of METER DEPOSIT. Further, the labor cost for connecting the distribution utility to the connection point shall be free of charge.
			    </p>
			    <p>11.	The Cooperative shall require a BILL DEPOSIT from customers of new and/or additional service equivalent to the estimated billing for one-month guarantee payment of bills. Provided that after one (1) year and every year thereafter, when the actual average monthly bills are more or less than the initial bill deposit, such deposit shall be correspondingly increased/decreased to approximate said billing. A customer who has paid his electric bills on or before its due date for three (3) consecutive years, may, however, demand for the full refund of the deposit even prior to the termination of his service upon application to the distribution utility provided all bills have been paid.
			    </p>
			    <p>12.	The Cooperative shall provide all electric meters to be installed or placed in service unless it has been tested, certified and sealed by the ERC. All watt-hour meters regardless of make and type before being placed in service must be adjusted to as close as possible to the condition of zero error.
			    </p>
			    <p>13.	The Cooperative, shall not, except in case of emergency, disconnect its service connection to the consumer's premises without at least 48 hours previous written notice, served upon the consumer by leaving a copy of same at his shop, office or usual place of abode, with any member of the family; servant or agent.
			    </p>
			    <p>14.	The Cooperative reserves the right to disconnect the service for any of the following reasons: (a) illegal use of electricity under R.A. 7832; (b) For non-payment of bills; (c) For repairs; (d) For want of supply or of distribution capacity; (e) request of the registered customers on voluntary disconnection based on justifiable reason; (f) upon lawful orders of government agencies and/or the courts; (g) when the cooperative and public safety so require; and (h) the customer to totally refrain from giving or allowing connection from his/her premises to another house, apartment, dwelling, etc. or extending a connection across the street.
			    </p>
			    <p>15.	The Cooperative shall when at a time of disconnection is to be made, the customer tenders payment of the unpaid bill to the agent or employee of the distribution utility shall desist from disconnecting the service to allow the customer to pay his bills within 24 hours; provided however, that the customer can only invoke provision once for the same unpaid bill.
			    </p>
			    <p>16.	The customer agrees to maintain the_ wiring installation and the service drop of the premises in proper and safe condition including its right of way through the life of the cooperative. The customer should be liable for any untoward incident that may occur on any damage to dilapidated condition of its service drop and accessories.
			    </p>
			    <p>17. The customer shall allow the employees and/or representative of the distribution utility to enter their premises for the purpose of 
			      inspecting, installing, reading, testing, repairing, maintaining, removing, replacing, or otherwise disposing of its apparatus and property 
			      and/or removing distribution utility's entire property in the event of termination of the electricity service contract: and for disconnection of 
			      service for non-payment of bills or violation of contract (VOC). Provided, however that only authorized employee and/or representatives 
			      of the distribution utility with proper identification cards shall be allowed to make any external adjustment of meter or any internal or 
			      external adjustment of any other pieces of apparatus owned by the distribution utility.
			    </p>
			    <p>18. The customer agrees that he/she or his/her authorized representative will witness the testing of the kilowatt hour meter found to be 
			      defective, removed/disconnected within the meter laboratory of the cooperative on the date and time specified.
			    </p>
			    <p>19. The Cooperative shall test once every two (2) years, free of charge, the accuracy of the meter installed in his premises making use of a 
			      meter standard duly tested and sealed by the ERC. If the customer requested for meter testing more than once every (2) years and the 
			      meter being tested is found to be within the tolerable limit is provided for in Article 9 hereof the Coop shall assess the customer a testing 
			      fee based on the testing charged by ERC. A written report showing the result of such test shall be furnished the customer. (Refer to BR 
			      No. 93-63. Service Charges.)
			    </p>
			    <p>20.  In the event that the meter in service is found to have an average error or more than the tolerance of plus two percent, the customer is 
			      entitled to a refund, fora maximum period of six (6) months prior to the date of discovery, to be applied to the customer's future billings.
			    </p>
			    <p>21. In the event of stoppage or failure of any meter to register the full amount of the energy consumed, the customer shall be billed for such 
			      period on an estimated consumption based on the load or upon his use of energy in a similar period of like use.
			    </p>
			    <p>22.  In the event the electric meter is installed in the customer's premises fails to register the actual amount of the energy used as result of 
			      the tampering of the meter and/or the installation of other illegal devices, the cooperative, shall adjust its bills to reflect the correct 
			      amount of the electric energy used in accordance to R.A. 7832. If the consumer fails to pay his adjusted bill, the cooperative without 
			      prejudice to the consumers criminal liability, is authorized to suspend its service to said customer. Such suspension of service shall not 
			      terminate this contract.
			    </p>
			    <p>23. The customer agrees to totally refrain from using or allowing connection from his/her premises of inductive motors. equipment welding 
			      machines without the required individual distribution transformer as specified by the cooperative. The cooperative shall have the right to 
			      disconnect the electric service of the customer without prior notice for his/her failure to provide necessary transformer.
			    </p>
			    <p>24. The customer shall allow the cooperative's personnel to conduct right of way clearing based on the required distance from the 
			      distribution line on all its properties including those not adjoining his connection of electric service. Likewise, the customer shall abide by 
			      the provision of the Provincial Ordinance No. 93-2000 by prohibiting construction of structures, planting of trees and other plant species 
			      having a potential growth in height of at least 17 feet and the burning of waste, grass, etc. underneath the distribution line and based on 
			      the clearing policy of the cooperative.
			    </p>
			    <p>25. The applicant shall pledge to subscribe to at least ten (10) shares of the cooperative and shall initially pay at least two (2) shares upon 
			      application and the balance to be divided and paid in equal installments for two (2) years at Php80.00 per month, and the value of one (1) 
			      share is Two hundred Forty Pesos (Php240.00) but shall not subscribe to more than ten percent (10`)/0) of the subscribed capital stock of 
			      the cooperative;
			    </p>
			    <p>26. Any violation of the Provision of his contract including Memorandum of Agreement signed between contracting parties shall give the 
			      cooperative the right to disconnect electric service upon demand without any need for judicial orders or any other authority from 
			      whomsoever.
			    </p>
			    <p>27.  It is agreed that all terms and stipulation made in relation to the electric service are contained in this contract and that no representation 
			      or agreement be made by the cooperative's officers and agents shall be binding upon the Board of Directors of this cooperative and by 
			      the proper governmental agency concerned is deemed part of this contract and may give rise to rights and obligations of the parties 
			      hereto to the extent applicable.
			    </p>
			    <div className="row">
			      <div className="col">
			        <p>ACCEPTED ON (Date)</p>
			        <p>Pangasinan I Electric Cooperative</p>
			        <p>By: Dionisio O. Opolento, Jr.</p>
			        <p>General Manager (or any Authorized Representative)</p>
			      </div>
			      <div className="col">
			        <p>Consumer's Printed Name/Signature</p>
			        <p>Res. Cert. No.</p>
			        <p>Issued on</p>
			        <p>Issued at:</p>
			        <p>Building/House Owner's Signature</p>
			      </div>
			    </div>
			    <h5 className="text-center">WITNESSES</h5>
			    <div className="row">
			      <div className="col">
			        <p>(Name)</p>
			        <p>Republic of the Philippines)</p>
			        <p>Province of Pangasinan) S.S.</p>
			        <p>City of Alaminos</p>
			      </div>
			      <div className="col">
			        <p>(Name)</p>
			      </div>
			    </div>
			    <h5 className="text-center">ACKNOWLEDGEMENT</h5>
			    <p>	Before me, a Notary Public for and in	(Fill Up), this (Fill Up) day of (Fill Up)
			      personally, appeared DIONISIO 0. OPOLENTO, JR., General Manager, Pangasinan I Electric Cooperative with SSS No. 02-0612326-0 and (Fill Up)
			      with Community Tax Certificate No. (Fill Up), issued on (Fill Up) at (Fill Up)
			      known to me and to me known to be the same persons who executed the foregoing
			      instrument, consisting of (Fill Up) pages, including the pages where the Acknowledgment is written, all pages signed by both
			      parties and their instrument witnesses, and they acknowledged before me that the same are their free and voluntary act and deed and that of the company they represent.
			    </p>
			    <div className="col">
			      <p>WITNESS MY HAND AND SEAL, on the date and place above written</p>
			      <p>Doc. No. (Fill Up)</p>
			      <p>Page No. (Fill Up)</p>
			      <p>Book No. (Fill Up)</p>
			      <p>Series of (Fill Up)</p>
			    </div>
			  </form>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">REQUIREMENTS FOR TRANSFER/CHANGE OF NAME PER POLICY NO. 4 SERIES OF 2017</h4>
			    <p className="text-center">(Approved Per BR NO. 17-88 Dated May 18, 2017)</p>
			  </header>
			  <p>1. VALID IDENTIFICATION (1 photocopy of any of the following)</p>
			  <div className="row">
			    <div className="col">
			      <dl>
			        <dd>-Senior Citizen's ID</dd>
			        <dd>-Phil. Passport</dd>
			        <dd>-Driver's License</dd>
			        <dd>-Voter's ID</dd>
			        <dd>-SSS/GSIS ID</dd>
			        <dd>-PRC Card</dd>
			        <dd>-Postal ID</dd>
			        <dd>-Philhealth ID</dd>
			        <dd>-4P'2 ID</dd>
			      </dl>
			    </div>
			    <div className="col">
			      <p>Please pay:</p>
			      <p>Membership fee: Php 100.00</p>
			      <p>ID Fee: Php 100.00</p>
			      <p>Notorial Fee: Php 50.00 (Metered Service Contract)</p>
			      <p>Total: Php 250.00</p>
			    </div>
			  </div>
			  <dl>
			    <dt>2. PROOF OF SUCCESSION TO THE PROPERTY/OWNERSHIP (1 photocopy of any of the following)</dt>
			    <dd>-Deed of Donation</dd>
			    <dd>-Deed of Sale</dd>
			    <dd>-Quit Claim</dd>
			    <dd>-Tax Declaration</dd>
			    <dd>-Certificate of Title</dd>
			    <dd>-Barangay Certificate showing proof of residency in the barangay and actual occupant of the house/building establishment</dd>
			    <dt>3. DEED OF ASSIGNMENT OF TENANTS (1 photocopy)</dt>
			    <dt>4. TAX IDENTIFICATION NUMBER & 2 DOCUMENTARY STAMP</dt>
			  </dl>
			</div>
			<div className="container">
			  <header>
			    <h4 className="text-center">COMPLAINT FORM</h4>
			  </header>
			  <form>
			    <div className="row">
			      <div className="form-col">
			        <div className="col-sm-12 mb-3">
			          <label htmlFor="name">Name :</label>
			          <input type="text" name="name" className="form-control"></input>
			        </div>
			        <div className="col-sm-12 mb-2">
			          <label htmlFor="address">Address :</label>
			          <input type="text" name="address" className="form-control"></input>
			        </div>
			        <div className="col-sm-12 mb-2">
			          <label htmlFor="pole-id">Pole ID (If available) :</label>
			          <input type="text" name="pole-id" className="form-control"></input>
			        </div>
			        <div className="col-sm-12 mb-4">
			          <label htmlFor="landmark">Landmark :</label>
			          <input type="text" name="landmark" className="form-control"></input>
			        </div>
			      </div>
			    </div>
			    <h5 className="text-center">Complaint Details</h5>
			    <div className="form-row">
			      <div className="col-sm-12 mb-2">
			        <label htmlFor="cause-of-tripping">Nature/Cause of Tripping :</label>
			        <textarea className="form-control" id="cause-of-tripping" rows="3"></textarea>
			      </div>
			      <div className="col-sm-4 mb-2">
			        <label htmlFor="feeder">Feeder :</label>
			        <input type="text" name="feeder" className="form-control"></input>
			      </div>
			      <div className="col-sm-4 mb-2">
			        <label htmlFor="phase">Phase :</label>
			        <input type="text" name="phase" className="form-control"></input>
			      </div>
			      <div className="col-sm-4 mb-2">
			        <label htmlFor="transformer">Transformer :</label>
			        <input type="text" name="transformer" className="form-control"></input>
			      </div>
			      <div className="col-sm-12 mb-2">
			        <label htmlFor="action-taken">Comment/Action Taken :</label>
			        <textarea className="form-control" id="action-taken" rows="3"></textarea>
			      </div>
			    </div>
			    <div className="form-row">
			      <div className="col-sm-6 mb-1">
			        <p>Time of Arrival</p>
			      </div>
			      <div className="col-sm-6 mb-1">
			        <p>Time of Departure</p>
			      </div>
			    </div>
			    <div className="form-row">
			      <div className="col-sm-3 mb-2">
			        <label htmlFor="date">Date :</label>
			        <input type="text" name="date" className="form-control"></input>
			      </div>
			      <div className="col-sm-3 mb-2">
			        <label htmlFor="time">Time :</label>
			        <input type="text" name="time" className="form-control"></input>
			      </div>
			      <div className="col-sm-3 mb-2">
			        <label htmlFor="Date">Date :</label>
			        <input type="text" name="Date" className="form-control"></input>
			      </div>
			      <div className="col-sm-3 mb-5">
			        <label htmlFor="time">Time :</label>
			        <input type="text" name="time" className="form-control"></input>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col">
			        <p>___________________</p>
			        <p>Consumer Name</p>
			      </div>
			      <div className="col">
			        <p>___________________</p>
			        <p>Attended by</p>
			      </div>
			    </div>
			  </form>
			    <div className="container">
			      <header>
			        <h4 className="text-center">METER COMPLAINT FORM</h4>
			      </header>
			  <form>
			  <div className="row">
			  <div className="form-col">
			  <div className="row-sm-12 mb-3">
			  <label htmlFor="date">Date :</label>
			  <input type="text" name="date" className="form-control"></input>
			  </div>
			  <div className="row-sm-12 mb-3">
			  <label htmlFor="name">Name :</label>
			  <input type="text" name="name" className="form-control"></input>
			  </div>
			  <div className="row-sm-12 mb-2">
			  <label htmlFor="address">Address :</label>
			  <input type="text" name="address" className="form-control"></input>
			  </div>
			  <div className="row-sm-12 mb-2">
			  <label htmlFor="account-number">Account number :</label>
			  <input type="text" name="account-number" className="form-control"></input>
			  </div>
			  <div className="row-sm-12 mb-4">
			  <label htmlFor="findings">Findings :</label>
			  <input type="text" name="findings" className="form-control"></input>
			  </div>
			  </div>
			  </div>
			  <p>Dear Madam/Sir;</p>
			  <p>This is line with the massive Inspection of kilowatt Hour Meters being conducted by Meter Technicians of the Technical Services Department of PANELCO-1.</p>
			  <p>We regret to Inform you that during our inspection, your Kilowatt Hour Meter was found defective due to (e.g. open potential, defective gears, out of calibration, creeping, worn out chassis and terminal, no display, error display, under rated kwh meter, burned meter) thus the result needs necessary replacement.</p>
			  <p>As a service provider, PANELCO-1 will issue a free kilowatt Hour Meter for those affected residential consumer, however you are advised to provide the following service drop and metering accessories prior to the replacement of new kilowatt hour meter.</p>
			  <div className="container">
			  <div className="row">
			  <div className="col">
			  <table className="table table-bordered">
			  <thead>
			  <tr>
			  <th scope="col" className="text-center">MATERIALS</th>
			  </tr>
			  </thead>
			  <tbody>
				  <tr>
					  <td className="row-sm-4 mb-1">
							<label htmlFor="kilowatt-hour-beter-base">Kilowatt Hour Meter Base</label>
							<input type="text" name="kilowatt-hour-beter-base" className="form-control"></input>
					  </td>
				  </tr>
				  <tr>
					  <td className="row-sm-1 mb-1">
							<label htmlFor="copper-wire">TW Cooper Wire #8</label>
							<input type="text" name="copper-wire" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-1 mb-1">
					  <td>
							<label htmlFor="metal-box">Metal Box</label>
							<input type="text" name="metal-box" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-1 mb-1">
					  <td>
							<label htmlFor="pvc-adapter">PVC Adapter 3/4 or 1"</label>
							<input type="text" name="pvc-adapter" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-1 mb-1">
					  <td>
							<label htmlFor="pvc-pipe">PVC Pipe 3/4 or 1"</label>
							<input type="text" name="pvc-pipe" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-1 mb-1">
					  <td>
							<label htmlFor="pvc-elbow">PVC Elbow 3/4 or 1"</label>
							<input type="text" name="pvc-elbow" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-2 mb-1">
					  <td>
							<label htmlFor="service-entrance">Service Entrance Cap</label>
							<input type="text" name="service-entrance" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-2 mb-1">
					  <td>
							<label htmlFor="duplex-wire">Duplex Wire #6</label>
							<input type="text" name="duplex-wire" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-2 mb-1">
					  <td>
							<label htmlFor="padlock-seal">Padlock Seal</label>
							<input type="text" name="padlock-seal" className="form-control"></input>
					  </td>
				  </tr>
				  <tr className="row-sm-2 mb-1">
					  <td>
							<label htmlFor="meter">Kilowatt Hour Meter</label>
							<input type="text" name="meter" className="form-control"></input>
					  </td>
				  </tr>
			  </tbody>
			  </table>
			  </div>
			  </div>
			  </div>
			  <p>Please be advised to seek the assistance of PANELCO-1 accredited electricians to undertake immediate installation of the above house wiring materials.</p>
			  <p>Furthermore, you may also visit our main office at PANELCO-1 San Jose, Bani, Pangasinan and please contact Mr. Rechie Penera, Representative from the Institutional Services Department to provide you the material cost estimate needed for the replacement of the KWH meter. You may also secure clearances from our Finance Services Department for possible billing adjustment of the unmetered and/or over registration of energy consumption.</p>
			  <p>this regard, failure to comply within five (5) days upon receipt of this letter will serve as sufficient grounds for the temporary disconnection of electric service,</p>
			  <p>Thank you and shall look forward to your Immediate attention on this matter.</p>
			  <p>Very truly yours,</p>
			  <p>RANDY G. PERALTA</p>
			  <p>Acting Manager - TSD</p>
			  <p>Received by : (Consumer Representative) Meter Technician/s: (Name)</p>
			  </form>
			  </div>
			</div>
      <CTAStrip/>
  	</>
  );
}

export default withRouter(ServiceNewConnection);
